import { jsx as _jsx } from "react/jsx-runtime";
import { PageLayout } from '@/components/layouts/PageLayout';
import { useDisclosure } from '@chakra-ui/react';
import { useManageUserPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useFacilityList } from '@frontend/domain/contexts/Facility/FacilityListHook';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useUserDirectory } from '@frontend/domain/contexts/User/UserDirectoryHook';
import React, { useState } from 'react';
import { UserManagementTable } from './UserManagement/UserManagementTable';
export var TableType;
(function (TableType) {
    TableType["org"] = "Org Management";
    TableType["user"] = "User Management";
    TableType["systemAdmin"] = "System Admin";
})(TableType || (TableType = {}));
export const SystemAdminPage = () => {
    const { user: currentUser } = useCurrentUser();
    const { facilities, fetchFacilities } = useFacilityList();
    const { canResendInvite, canDelete } = useManageUserPermissions();
    const { userDirectory, assignUserAccess, addNewUser, resendInvite, offboardUser, } = useUserDirectory({
        requiresOrg: false,
    });
    const { organizations } = useOrganizationSummaries();
    const [userToEdit, setUserToEdit] = useState();
    const openUserDisclosure = useDisclosure();
    const deleteUserDisclosure = useDisclosure();
    const openWithUser = (onOpen, identity) => {
        const user = userDirectory.find((user) => user.identity === identity);
        setUserToEdit(user);
        onOpen();
    };
    const closeWithUser = (onClose) => {
        setUserToEdit(undefined);
        onClose();
    };
    return (_jsx(PageLayout, { title: 'System Admin', hideOrgsDropdown: true, hideAddUserButton: false, onOpenAddUser: 
        // ensure no user to edit
        () => openWithUser(openUserDisclosure.onOpen, undefined), children: _jsx(UserManagementTable, { userDirectory: userDirectory, canResendInvite: canResendInvite, resendInvite: resendInvite, addNewUser: addNewUser, assignUserAccess: assignUserAccess, onOffboardUser: offboardUser, canOffboardUser: canDelete, currentUser: currentUser, organizations: organizations, facilities: facilities, fetchFacilities: fetchFacilities, openWithUser: openWithUser, closeWithUser: closeWithUser, openUserDisclosure: openUserDisclosure, deleteUserDisclosure: deleteUserDisclosure, userToEdit: userToEdit }) }));
};
