import { jsx as _jsx } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { Table } from '@frontend/design-system/components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { OrganizationRoleEnum } from '../../../objects/UserData';
import { FacilityExceptionsTabs } from './FacilityExceptionsTabs';
const columnHelper = createColumnHelper();
export const UserOrgRolesTable = ({ data, form, facilities, setSelectedOrgId, }) => {
    const [facilityExceptionsLength, setFacilityExceptionsLength] = useState(new Map());
    useEffect(() => {
        const updatedFacilityExceptionsLength = new Map();
        data.forEach((_, index) => {
            const facilityExceptions = form.watch(`organizationAssignments.${index}.facilityExceptions`);
            updatedFacilityExceptionsLength.set(index, (facilityExceptions === null || facilityExceptions === void 0 ? void 0 : facilityExceptions.length) || 0);
        });
        setFacilityExceptionsLength(updatedFacilityExceptionsLength);
    }, [data, form.watch]);
    const columns = [
        columnHelper.accessor('organization', {
            cell: (info) => {
                const { organization } = info.row.original;
                return (_jsx(Box, { display: '-webkit-box', "data-testid": 'name', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                    }, children: organization }));
            },
            header: 'Organization',
            enableSorting: false,
        }),
        columnHelper.accessor('role', {
            cell: (info) => {
                const { role } = info.row.original;
                return (_jsx(Box, { display: '-webkit-box', "data-testid": 'role', w: '15em', whiteSpace: 'pre-line', style: {
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                    }, children: role }));
            },
            header: 'Organization Role',
            enableSorting: false,
        }),
        columnHelper.accessor('appAccess', {
            cell: (info) => {
                const { appAccess } = info.row.original;
                return (_jsx(Box, { display: '-webkit-box', "data-testid": 'appAccess', w: '10em', children: appAccess }));
            },
            header: 'Access',
            enableSorting: false,
        }),
        columnHelper.accessor('orgAssignmentRemove', {
            cell: (info) => {
                const { orgAssignmentRemove } = info.row.original;
                return (_jsx(Box, { display: '-webkit-box', "data-testid": 'orgAssignmentRemove', children: orgAssignmentRemove }));
            },
            header: '',
            enableSorting: false,
        }),
        {
            id: 'exceptions',
            cell: (info) => {
                const { roleValue } = info.row.original;
                const isVisitorOrMember = roleValue === OrganizationRoleEnum.VISITOR ||
                    roleValue === OrganizationRoleEnum.MEMBER;
                const facilityExceptionsCount = facilityExceptionsLength.get(info.row.index) || 0;
                return (_jsx(Box, { textAlign: 'center', "data-testid": 'exceptions', w: '5em', children: isVisitorOrMember
                        ? `(${facilityExceptionsCount})`
                        : '' }));
            },
            header: '',
            enableSorting: false,
        },
    ];
    const tableData = data.map((orgRolesTable, index) => {
        const bgColor = '';
        const role = form.getValues().organizationAssignments[index].role;
        if (role === OrganizationRoleEnum.MEMBER ||
            role === OrganizationRoleEnum.VISITOR) {
            return Object.assign(Object.assign({}, orgRolesTable), { style: {
                    backgroundColor: bgColor,
                }, seeMore: (_jsx(FacilityExceptionsTabs, { selectedOrgRole: role, form: form, facilities: facilities, orgIndex: index, setSelectedOrgId: setSelectedOrgId })) });
        }
        else {
            return Object.assign(Object.assign({}, orgRolesTable), { style: {
                    backgroundColor: bgColor,
                } });
        }
    });
    return (_jsx(Box, { w: '100%', children: _jsx(Table, { maxHeight: '85vh', data: tableData, columns: columns, dropdown: (isExpanded) => {
                if (isExpanded) {
                    return _jsx(ChevronUpIcon, {});
                }
                else {
                    return _jsx(ChevronDownIcon, {});
                }
            } }) }));
};
