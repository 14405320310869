import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, VStack, } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import PreTitleStrong from '@frontend/design-system/components/Typography/PreTitleStrong/PreTitleStrong';
export const DeleteModal = ({ isOpen, onClose, deleteItem, title, subheading, description, buttonText = 'Delete', }) => {
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, size: 'lg', children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { borderRadius: '2xl', filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: [_jsx(ModalHeader, { color: 'secondary.dark.500', fontSize: 36, fontWeight: 400, children: title }), _jsx(ModalCloseButton, { size: 'sm' }), _jsx(ModalBody, { children: _jsxs(VStack, { gap: 5, alignItems: 'start', children: [subheading ? (_jsx(PreTitleStrong, { children: subheading })) : null, _jsx(Body, { children: description })] }) }), _jsx(ModalFooter, { pt: 5, children: _jsxs(Flex, { w: '100%', children: [_jsx(Button, { onClick: onClose, type: 'button', buttonType: 'secondary', text: 'Cancel' }), _jsx(Spacer, {}), _jsx(Button, { onClick: () => {
                                        deleteItem();
                                    }, type: 'button', buttonType: 'primary', color: 'ui.danger', text: buttonText })] }) })] })] }));
};
