import { faker } from '@faker-js/faker';
import { IAppNames } from '../models/App';
import { FacilityRoleEnum, OrganizationRoleEnum, SystemRole, } from '../models/Roles';
import { IOrganizationSnapshotBuilder } from './platformOrganizationMocks';
export class IUserBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), firstName: faker.person.firstName(), lastName: faker.person.lastName(), emailAddress: faker.internet.email(), phoneNumber: faker.phone.number(), jobTitle: faker.person.jobTitle(), iconUrl: faker.image.url(), systemRole: faker.helpers.arrayElement([
                    SystemRole.SUPER_ADMINISTRATOR,
                    SystemRole.ORGANIZATION_APPROVER,
                    SystemRole.USER,
                ]), appAccess: [], organizationRoles: [], facilityExceptions: [], primaryContactOrganizations: [], isActive: true, isEulaAccepted: true }, this.overrides);
        };
        this.overrides = overrides;
    }
    addApp(appOverrides) {
        const app = new AppBuilder(appOverrides).build();
        if (this.overrides.appAccess) {
            this.overrides.appAccess.push(app);
        }
        else {
            this.overrides.appAccess = [app];
        }
        return this;
    }
    addOrganizationRole(roleOverrides) {
        const organizationRole = new IOrganizationRoleBuilder(roleOverrides).build();
        if (this.overrides.organizationRoles) {
            this.overrides.organizationRoles.push(organizationRole);
        }
        else {
            this.overrides.organizationRoles = [organizationRole];
        }
        return this;
    }
    addFacilityException(exceptionOverrides) {
        const facilityException = new IFacilityExceptionBuilder(exceptionOverrides).build();
        if (this.overrides.facilityExceptions) {
            this.overrides.facilityExceptions.push(facilityException);
        }
        else {
            this.overrides.facilityExceptions = [facilityException];
        }
        return this;
    }
    addPrimaryContactOrganizations(primaryContactOverrides) {
        const primaryContactOrganization = new IOrganizationSnapshotBuilder(primaryContactOverrides).build();
        if (this.overrides.primaryContactOrganizations) {
            this.overrides.primaryContactOrganizations.push(primaryContactOrganization);
        }
        else {
            this.overrides.primaryContactOrganizations = [
                primaryContactOrganization,
            ];
        }
        return this;
    }
}
export class AppBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.helpers.arrayElement([
                    IAppNames.REPORTS,
                    IAppNames.FCA,
                    IAppNames.PLANNER,
                    IAppNames.PIQ,
                ]), enabled: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IOrganizationRoleBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), role: faker.helpers.arrayElement([
                    OrganizationRoleEnum.CLIENT_CONCIERGE,
                    OrganizationRoleEnum.LOCAL_ADMIN,
                    OrganizationRoleEnum.MEMBER,
                    OrganizationRoleEnum.VISITOR,
                ]), name: faker.company.name() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IFacilityExceptionBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), facilityIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), role: faker.helpers.arrayElement(Object.values(FacilityRoleEnum)) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class UserAssignFormBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), firstName: faker.person.firstName(), lastName: faker.person.lastName(), email: faker.internet.email(), systemRole: SystemRole.USER, organizationAssignments: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class UserProfileResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ userId: faker.number.int({ min: undefined, max: undefined }), firstName: faker.person.firstName(), lastName: faker.person.lastName(), emailAddress: faker.internet.email(), phoneNumber: faker.phone.number(), jobTitle: faker.person.jobTitle(), systemRole: faker.helpers.arrayElement([
                    'SuperAdministrator',
                    'OrganizationApprover',
                    'User',
                ]), organizationAssignments: [], isActive: true, isEulaAccepted: true, iconUrl: faker.image.url(), primaryContactOrganizations: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
    addOrganizationAssignment(assignmentOverrides) {
        const organizationAssignment = new OrganizationAssignmentResponseBuilder(assignmentOverrides).build();
        if (this.overrides.organizationAssignments) {
            this.overrides.organizationAssignments.push(organizationAssignment);
        }
        else {
            this.overrides.organizationAssignments = [organizationAssignment];
        }
        return this;
    }
    addPrimaryContactOrganizations(primaryContactOverrides) {
        const primaryContactOrganization = new PrimaryContactOrganizationBuilder(primaryContactOverrides).build();
        if (this.overrides.primaryContactOrganizations) {
            this.overrides.primaryContactOrganizations.push(primaryContactOrganization);
        }
        else {
            this.overrides.primaryContactOrganizations = [
                primaryContactOrganization,
            ];
        }
        return this;
    }
}
export class OrganizationAssignmentResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), role: faker.helpers.arrayElement(Object.values(OrganizationRoleEnum)), organizationName: faker.lorem.slug(1), facilityAssignments: [], apps: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
    addFacilityAssignment(facilityAssignmentOverrides) {
        const facilityAssignment = new FacilityAssignmentResponseBuilder(facilityAssignmentOverrides).build();
        if (this.overrides.facilityAssignments) {
            this.overrides.facilityAssignments.push(facilityAssignment);
        }
        else {
            this.overrides.facilityAssignments = [facilityAssignment];
        }
        return this;
    }
    addAppAssignment(appAssignmentOverrides) {
        const appAssignment = new UserAppAssignmentResponseBuilder(appAssignmentOverrides).build();
        if (this.overrides.apps) {
            this.overrides.apps.push(appAssignment);
        }
        else {
            this.overrides.apps = [appAssignment];
        }
        return this;
    }
}
export class UserAppAssignmentResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ appName: faker.helpers.arrayElement(Object.values(IAppNames)), isActive: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityAssignmentResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ facilityId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), role: faker.helpers.arrayElement(['Member', 'Visitor', 'None']) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class PrimaryContactOrganizationBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), organizationName: faker.company.name() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
