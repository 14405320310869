var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as ChakraButton, IconButton } from '@chakra-ui/react';
import React from 'react';
const padding = {
    x: {
        small: 5,
        medium: 5,
    },
    y: {
        small: 1,
        medium: 2,
    },
};
const Button = (_a) => {
    var { type = 'button', buttonType = 'primary', size = 'medium', isDisabled = false } = _a, props = __rest(_a, ["type", "buttonType", "size", "isDisabled"]);
    return props.isIcon ? (_jsx(IconButton, { type: type, variant: buttonType, icon: props.icon, "aria-label": 'icon', height: 'auto', borderColor: buttonType === 'secondary' ? props.color : undefined, background: buttonType === 'secondary' ? undefined : props.color, color: buttonType === 'secondary' ? props.color : undefined, px: buttonType === 'customLink' ? 2 : padding.x[size], py: buttonType === 'customLink' ? 0.5 : padding.y[size], isDisabled: isDisabled, onClick: props.onClick })) : (_jsx(ChakraButton, { type: type, variant: buttonType, height: 'auto', leftIcon: props.iconPosition === 'left' ? props.icon : undefined, rightIcon: props.iconPosition === 'right' ? props.icon : undefined, borderColor: buttonType === 'secondary' ? props.color : undefined, background: buttonType === 'secondary' ? undefined : props.color, color: buttonType === 'secondary' ? props.color : undefined, px: buttonType === 'customLink' ? 2 : padding.x[size], py: buttonType === 'customLink' ? 0 : padding.y[size], isDisabled: isDisabled, onClick: props.onClick, lineHeight: 1, children: props.text }));
};
export default Button;
