import { convertSimpleObjectListToFormDataArray } from '../../conversion/FormDataConversions';
import { stringToInt } from '../../conversion/NumberConversions';
import { FriendlyYesNoValues, PropertySizes, } from './FacilityInterfaces';
export const convertSizeTypeToPropertySizes = (sizeType) => {
    switch (sizeType) {
        case PropertySizes.UNITS:
            return PropertySizes.UNITS;
        case PropertySizes.KEYS:
            return PropertySizes.KEYS;
        default:
            return PropertySizes.SQFT;
    }
};
export const resolvePropertySizeType = (facility) => {
    var _a, _b, _c;
    switch (facility.primaryFacilityType) {
        case 'Hospitality':
            return {
                value: (_a = facility.keyCount) !== null && _a !== void 0 ? _a : 0,
                type: PropertySizes.KEYS,
            };
        case 'Housing':
            return {
                value: (_b = facility.unitCount) !== null && _b !== void 0 ? _b : 0,
                type: PropertySizes.UNITS,
            };
        default:
            return {
                value: (_c = facility.originalSquareFootage) !== null && _c !== void 0 ? _c : 0,
                type: PropertySizes.SQFT,
            };
    }
};
export const adjustPropertySize = (originalFacility, updateRequest) => {
    var _a, _b, _c;
    switch (originalFacility.primaryFacilityType) {
        case 'Hospitality':
            return {
                keyCount: (_a = updateRequest.propertySize) !== null && _a !== void 0 ? _a : originalFacility.keyCount,
            };
        case 'Housing':
            return {
                unitCount: (_b = updateRequest.propertySize) !== null && _b !== void 0 ? _b : originalFacility.unitCount,
            };
        default:
            return {
                originalSquareFootage: (_c = updateRequest.propertySize) !== null && _c !== void 0 ? _c : originalFacility.originalSquareFootage,
            };
    }
};
export const convertFacilityDetailResponseToIFacility = (facility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return {
        identity: facility.facilityId,
        siteName: facility.siteName,
        name: facility.name,
        address1: facility.location.address1,
        address2: (_a = facility.location.address2) !== null && _a !== void 0 ? _a : undefined,
        city: facility.location.city,
        stateOrProvince: facility.location.stateOrProvince,
        postalCode: facility.location.postalCode,
        latitude: facility.location.latitude,
        longitude: facility.location.longitude,
        photoName: (_c = (_b = facility.photo) === null || _b === void 0 ? void 0 : _b.fileName) !== null && _c !== void 0 ? _c : undefined,
        photoUrl: (_e = (_d = facility.photo) === null || _d === void 0 ? void 0 : _d.photoUrl) !== null && _e !== void 0 ? _e : undefined,
        yearBuilt: facility.yearBuilt,
        primaryFacilityType: facility.primaryFacilityType,
        secondaryFacilityType: (_f = facility.secondaryFacilityType) !== null && _f !== void 0 ? _f : undefined,
        tertiaryFacilityType: (_g = facility.tertiaryFacilityType) !== null && _g !== void 0 ? _g : undefined,
        propertySize: resolvePropertySizeType(facility),
        customerInternalId: (_h = facility.customerInternalId) !== null && _h !== void 0 ? _h : undefined,
        propertyManager: (_j = facility.propertyManager) !== null && _j !== void 0 ? _j : undefined,
        hasPropertySurvey: facility.hasPropertySurvey ? 'Yes' : 'No',
        hasCompletedAudit: facility.hasCompletedAudit ? 'Yes' : 'No',
        facilityGrouper1: (_k = facility.facilityGrouper1) !== null && _k !== void 0 ? _k : undefined,
        facilityGrouper2: (_l = facility.facilityGrouper2) !== null && _l !== void 0 ? _l : undefined,
        ownershipType: (_m = facility.ownershipType) !== null && _m !== void 0 ? _m : undefined,
        additions: facility.additions.map((addition) => convertFacilityAdditionResponseToIFacilityAddition(addition)),
        enrollment: facility.enrollmentYears.map(convertFacilityEnrollmentResponseToIFacilityEnrollment),
    };
};
export const convertFacilityAdditionResponseToIFacilityAddition = (addition) => {
    return {
        identity: addition.additionId,
        yearBuilt: addition.yearBuilt,
        squareFootage: addition.squareFootage,
    };
};
export const convertFacilityEnrollmentResponseToIFacilityEnrollment = (enrollment) => {
    return {
        schoolYear: enrollment.schoolYear,
        gradeEnrollment: enrollment.gradeEnrollment.map((grade) => convertGradeEnrollmentResponseToIGradeEnrollment(grade)),
    };
};
const convertGradeEnrollmentResponseToIGradeEnrollment = (gradeEnrollment) => {
    return {
        gradeLevel: gradeEnrollment.gradeLevel,
        count: gradeEnrollment.count,
    };
};
export const convertFacilityTemplateResponseToIFacilityTemplate = (template) => {
    return {
        primaryFacilityTypes: template.primaryFacilityTypes.map(convertPrimaryFacilityTypeResponseToIFacilityType),
        secondaryFacilityTypes: template.secondaryFacilityTypes.map(convertFacilityTypeResponseToIFacilityType),
        tertiaryFacilityTypes: template.tertiaryFacilityTypes.map(convertFacilityTypeResponseToIFacilityType),
        schoolYears: template.schoolYears,
        gradeLevels: template.gradeLevels,
        ownershipTypes: template.ownershipTypes,
        importTemplateUrl: template.facilityTemplateUrl,
        siteNames: template.siteNames,
    };
};
const convertPrimaryFacilityTypeResponseToIFacilityType = (facilityType) => {
    return {
        identity: facilityType.facilityTypeId,
        name: facilityType.facilityTypeName,
        sizeType: convertSizeTypeToPropertySizes(facilityType.sizeType),
        hasEnrollment: facilityType.hasEnrollment,
    };
};
const convertFacilityTypeResponseToIFacilityType = (facilityType) => {
    return {
        identity: facilityType.facilityTypeId,
        name: facilityType.facilityTypeName,
        parentFacilityTypeId: facilityType.parentFacilityTypeId,
    };
};
/** Only used for PIQ facility edit conversions look to clean up when applying to PIQ*/
export const convertIUpdateFacilityToIFacility = (facility, originalFacility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return Object.assign(Object.assign({}, originalFacility), { name: (_a = facility.name) !== null && _a !== void 0 ? _a : originalFacility.name, address1: (_b = facility.address1) !== null && _b !== void 0 ? _b : originalFacility.address1, address2: (_c = facility.address2) !== null && _c !== void 0 ? _c : originalFacility.address2, city: (_d = facility.city) !== null && _d !== void 0 ? _d : originalFacility.city, stateOrProvince: (_e = facility.stateOrProvince) !== null && _e !== void 0 ? _e : originalFacility.stateOrProvince, postalCode: (_f = facility.postalCode) !== null && _f !== void 0 ? _f : originalFacility.postalCode, yearBuilt: (_g = facility.yearBuilt) !== null && _g !== void 0 ? _g : originalFacility.yearBuilt, note: (_h = facility.note) !== null && _h !== void 0 ? _h : originalFacility.note, enrollment: (_j = facility.enrollment) !== null && _j !== void 0 ? _j : originalFacility.enrollment, primaryFacilityType: (_k = facility.primaryFacilityType) !== null && _k !== void 0 ? _k : originalFacility.primaryFacilityType, secondaryFacilityType: (_l = facility.secondaryFacilityType) !== null && _l !== void 0 ? _l : originalFacility.secondaryFacilityType, tertiaryFacilityType: (_m = facility.tertiaryFacilityType) !== null && _m !== void 0 ? _m : originalFacility.tertiaryFacilityType, customerInternalId: (_o = facility.customerInternalId) !== null && _o !== void 0 ? _o : originalFacility.customerInternalId, propertyManager: (_p = facility.propertyManager) !== null && _p !== void 0 ? _p : originalFacility.propertyManager, ownershipType: (_q = facility.ownershipType) !== null && _q !== void 0 ? _q : originalFacility.ownershipType, hasPropertySurvey: facility.hasPropertySurvey
            ? facility.hasPropertySurvey
            : originalFacility.hasPropertySurvey
                ? 'Yes'
                : 'No', hasCompletedAudit: facility.hasCompletedAudit
            ? facility.hasCompletedAudit
            : originalFacility.hasCompletedAudit
                ? 'Yes'
                : 'No', facilityGrouper1: (_r = facility.facilityGrouper1) !== null && _r !== void 0 ? _r : originalFacility.facilityGrouper1, facilityGrouper2: (_s = facility.facilityGrouper2) !== null && _s !== void 0 ? _s : originalFacility.facilityGrouper2 });
};
export const convertFacilitySummaryResponseToIFacilitySummary = (facility) => {
    var _a, _b, _c, _d;
    return {
        identity: facility.facilityId,
        name: facility.name,
        siteName: facility.siteName,
        location: convertFacilityLocationResponseToILocation(facility.location),
        isRemovable: facility.isRemovable,
        photoUrl: (_a = facility.photoUrl) !== null && _a !== void 0 ? _a : undefined,
        totalSize: (_b = facility.totalSize) !== null && _b !== void 0 ? _b : undefined,
        sizeUnits: (_c = facility.sizeUnits) !== null && _c !== void 0 ? _c : PropertySizes.SQFT,
        latestEnrolledCount: (_d = facility.latestEnrolledCount) !== null && _d !== void 0 ? _d : undefined,
        yearBuilt: facility.yearBuilt,
    };
};
export const convertFacilityLocationResponseToILocation = (location) => {
    var _a;
    return {
        address1: location.address1,
        address2: (_a = location.address2) !== null && _a !== void 0 ? _a : undefined,
        city: location.city,
        stateOrProvince: location.stateOrProvince,
        postalCode: location.postalCode,
        latitude: location.latitude,
        longitude: location.longitude,
    };
};
export const convertIFacilityToFacilityForm = (facility, facilityTemplate) => {
    var _a;
    const primaryFacilityType = convertFacilityTypeNameToIdentity(facility === null || facility === void 0 ? void 0 : facility.primaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.primaryFacilityTypes);
    const secondaryFacilityType = convertFacilityTypeNameToIdentity(facility === null || facility === void 0 ? void 0 : facility.secondaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.secondaryFacilityTypes, primaryFacilityType);
    const tertiaryFacilityType = convertFacilityTypeNameToIdentity(facility === null || facility === void 0 ? void 0 : facility.tertiaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.tertiaryFacilityTypes, secondaryFacilityType);
    return {
        name: (facility === null || facility === void 0 ? void 0 : facility.name) || '',
        siteName: (facility === null || facility === void 0 ? void 0 : facility.siteName) || '',
        address: convertIFacilityAddressFieldsToIConfigureLocation(facility),
        ownershipType: (_a = facility === null || facility === void 0 ? void 0 : facility.ownershipType) !== null && _a !== void 0 ? _a : '',
        primaryFacilityType: primaryFacilityType,
        secondaryFacilityType: secondaryFacilityType,
        tertiaryFacilityType: tertiaryFacilityType,
        propertySize: (facility === null || facility === void 0 ? void 0 : facility.propertySize.value.toString()) || '',
        propertyType: (facility === null || facility === void 0 ? void 0 : facility.propertySize.type) || PropertySizes.SQFT,
        yearBuilt: (facility === null || facility === void 0 ? void 0 : facility.yearBuilt) ? facility.yearBuilt.toString() : '',
        additions: (facility === null || facility === void 0 ? void 0 : facility.additions)
            ? convertIFacilityAdditionToIConfigureAdditionForm(facility === null || facility === void 0 ? void 0 : facility.additions)
            : [],
        photo: facility === null || facility === void 0 ? void 0 : facility.photoUrl,
        enrollmentYears: (facility === null || facility === void 0 ? void 0 : facility.enrollment)
            ? convertIFacilityEnrollmentToFacilityEnrollmentForm(facility === null || facility === void 0 ? void 0 : facility.enrollment)
            : [],
    };
};
const convertFacilityTypeNameToIdentity = (facilityType, facilityTypes, parentIdentity) => {
    const foundType = facilityTypes === null || facilityTypes === void 0 ? void 0 : facilityTypes.find((type) => type.name === facilityType &&
        type.parentFacilityTypeId === parentIdentity);
    return foundType === null || foundType === void 0 ? void 0 : foundType.identity;
};
export const convertIConfigureLocationToLocationString = (location) => {
    if (location) {
        const { address1, address2, city, stateOrProvince, postalCode } = location;
        const addressList = [];
        if (address1 !== '') {
            addressList.push(address1);
        }
        if (address2 !== '' && address2 !== undefined) {
            addressList.push(` ${address2}`);
        }
        if (city !== '') {
            addressList.push(`, ${city}`);
        }
        if (stateOrProvince !== '') {
            addressList.push(`, ${stateOrProvince}`);
        }
        if (postalCode !== '') {
            addressList.push(` ${postalCode}`);
        }
        return addressList.length > 0 ? addressList.join('') : '';
    }
    else {
        return '';
    }
};
export const convertIFacilityAddressFieldsToIConfigureLocation = (facility) => {
    return {
        address1: (facility === null || facility === void 0 ? void 0 : facility.address1) || '',
        address2: (facility === null || facility === void 0 ? void 0 : facility.address2) || '',
        city: (facility === null || facility === void 0 ? void 0 : facility.city) || '',
        stateOrProvince: (facility === null || facility === void 0 ? void 0 : facility.stateOrProvince) || '',
        postalCode: (facility === null || facility === void 0 ? void 0 : facility.postalCode) || '',
    };
};
const convertIFacilityEnrollmentToFacilityEnrollmentForm = (enrollmentYears) => {
    return enrollmentYears.map((enrollmentYear) => {
        return {
            schoolYear: enrollmentYear.schoolYear,
            gradeEnrollment: convertIGradeEnrollmentToGradeEnrollmentForm(enrollmentYear.gradeEnrollment),
        };
    });
};
const convertIGradeEnrollmentToGradeEnrollmentForm = (gradeEnrollments) => {
    return gradeEnrollments.map((gradeEnrollment) => {
        return {
            gradeLevel: gradeEnrollment.gradeLevel,
            count: gradeEnrollment.count.toString(),
        };
    });
};
export const convertIFacilityAdditionToIConfigureAdditionForm = (additions) => {
    return additions.map((addition) => {
        return {
            identity: addition.identity,
            yearBuilt: addition.yearBuilt.toString(),
            squareFootage: addition.squareFootage.toString(),
        };
    });
};
export const convertFacilityFormToIConfigureFacility = (formData, facilityIdentity, facilityTemplate, facilityPhoto) => {
    const primaryFacilityType = convertFacilityTypeIdentityToName(formData.primaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.primaryFacilityTypes);
    if (primaryFacilityType === undefined) {
        console.error('Primary Facility Type not found.');
        return undefined;
    }
    const configureFacility = {
        identity: facilityIdentity,
        name: formData.name,
        siteName: formData.siteName,
        address1: formData.address.address1,
        address2: formData.address.address2,
        city: formData.address.city,
        stateOrProvince: formData.address.stateOrProvince,
        postalCode: formData.address.postalCode,
        yearBuilt: stringToInt(formData.yearBuilt),
        primaryFacilityType: primaryFacilityType,
        secondaryFacilityType: convertFacilityTypeIdentityToName(formData.secondaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.secondaryFacilityTypes),
        tertiaryFacilityType: convertFacilityTypeIdentityToName(formData.tertiaryFacilityType, facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.tertiaryFacilityTypes),
        ownershipType: formData.ownershipType,
        additions: formData.additions
            ? convertIConfigureAdditionFormToIConfigureAddition(formData.additions)
            : [],
        facilityPhoto,
        enrollment: formData.enrollmentYears
            ? convertFacilityEnrollmentFormToIFacilityEnrollment(formData.enrollmentYears)
            : [],
    };
    return Object.assign(Object.assign({}, configureFacility), convertPropertySize(parseInt(formData.propertySize), formData.propertyType));
};
const convertFacilityTypeIdentityToName = (identity, facilityTypes) => {
    const foundType = facilityTypes === null || facilityTypes === void 0 ? void 0 : facilityTypes.find((type) => type.identity === identity);
    if (!foundType) {
        return undefined;
    }
    return foundType.name;
};
const convertIConfigureAdditionFormToIConfigureAddition = (additions) => {
    return additions.map((addition) => {
        return {
            identity: addition.identity,
            yearBuilt: Number(addition.yearBuilt),
            squareFootage: Number(addition.squareFootage),
        };
    });
};
const convertFacilityEnrollmentFormToIFacilityEnrollment = (enrollments) => {
    return enrollments.map((enrollment) => {
        return {
            schoolYear: enrollment.schoolYear,
            gradeEnrollment: enrollment.gradeEnrollment
                ? convertGradeEnrollmentFormToIGradeEnrollment(enrollment.gradeEnrollment)
                : [],
        };
    });
};
const convertGradeEnrollmentFormToIGradeEnrollment = (grades) => {
    return grades.map((grade) => {
        return {
            gradeLevel: grade.gradeLevel,
            count: Number(grade.count),
        };
    });
};
const convertPropertySize = (propertySize, propertyType) => {
    switch (propertyType) {
        case PropertySizes.KEYS:
            return {
                keyCount: propertySize,
                unitCount: undefined,
                originalSquareFootage: undefined,
            };
        case PropertySizes.UNITS:
            return {
                keyCount: undefined,
                unitCount: propertySize,
                originalSquareFootage: undefined,
            };
        default:
            return {
                keyCount: undefined,
                unitCount: undefined,
                originalSquareFootage: propertySize,
            };
    }
};
export const convertIConfigureFacilityToFacilityFormData = (facility, orgIdentity) => {
    var _a, _b, _c, _d;
    const formRequest = {
        FacilityId: facility.identity,
        Name: facility.name,
        OrganizationId: orgIdentity,
        SiteName: facility.siteName,
        Address1: facility.address1,
        Address2: facility.address2,
        City: facility.city,
        StateOrProvince: facility.stateOrProvince,
        PostalCode: facility.postalCode,
        YearBuilt: facility.yearBuilt,
        PrimaryFacilityType: facility.primaryFacilityType,
        SecondaryFacilityType: facility.secondaryFacilityType,
        TertiaryFacilityType: facility.tertiaryFacilityType,
        OwnershipType: facility.ownershipType,
        OriginalSquareFootage: facility.originalSquareFootage,
        KeyCount: facility.keyCount,
        UnitCount: facility.unitCount,
        CustomerInternalId: facility.customerInternalId,
        PropertyManager: facility.propertyManager,
        HasPropertySurvey: ((_a = facility.hasPropertySurvey) === null || _a === void 0 ? void 0 : _a.toString()) === FriendlyYesNoValues.Yes,
        HasCompletedAudit: ((_b = facility.hasCompletedAudit) === null || _b === void 0 ? void 0 : _b.toString()) === FriendlyYesNoValues.Yes,
        FacilityGrouper1: facility.facilityGrouper1,
        FacilityGrouper2: facility.facilityGrouper2,
        'FacilityPhoto.FileName': (_c = facility.facilityPhoto) === null || _c === void 0 ? void 0 : _c.fileName,
        'FacilityPhoto.File': (_d = facility.facilityPhoto) === null || _d === void 0 ? void 0 : _d.file, //4182: adjust as part of adding logo card
    };
    const additions = convertSimpleObjectListToFormDataArray('Additions', facility.additions || []);
    const enrollmentYears = convertEnrollmentYearsToFormDataNestedArray(facility.enrollment || []);
    return Object.assign(Object.assign(Object.assign({}, formRequest), additions), enrollmentYears);
};
const convertEnrollmentYearsToFormDataNestedArray = (enrollmentYears) => {
    const updatedEnrollmentYears = enrollmentYears.map((enrollment) => {
        const gradeEnrollment = convertSimpleObjectListToFormDataArray('gradeEnrollment', enrollment.gradeEnrollment);
        return Object.assign({ schoolYear: enrollment.schoolYear }, gradeEnrollment);
    });
    return convertSimpleObjectListToFormDataArray('EnrollmentYears', updatedEnrollmentYears);
};
export const convertIFacilitytoIFacilitySummary = (facilityToUpdate, updatedFacility) => {
    var _a, _b;
    const latestEnrolledCount = (_b = (_a = updatedFacility.enrollment) === null || _a === void 0 ? void 0 : _a.slice().sort((a, b) => {
        const [startYearA] = a.schoolYear.split('-').map(Number);
        const [startYearB] = b.schoolYear.split('-').map(Number);
        return startYearB - startYearA;
    })[0]) === null || _b === void 0 ? void 0 : _b.gradeEnrollment.reduce((total, grade) => total + grade.count, 0);
    if (updatedFacility.propertySize.type === PropertySizes.SQFT) {
        const additionSize = updatedFacility.additions.reduce((sum, addition) => sum + addition.squareFootage, 0);
        updatedFacility.propertySize.value += additionSize;
    }
    return {
        identity: updatedFacility.identity,
        name: updatedFacility.name,
        siteName: updatedFacility.siteName,
        location: {
            address1: updatedFacility.address1,
            address2: updatedFacility.address2,
            city: updatedFacility.city,
            stateOrProvince: updatedFacility.stateOrProvince,
            postalCode: updatedFacility.postalCode,
            latitude: updatedFacility.latitude,
            longitude: updatedFacility.longitude,
        },
        photoUrl: updatedFacility.photoUrl,
        sizeUnits: updatedFacility.propertySize.type,
        totalSize: updatedFacility.propertySize.value,
        latestEnrolledCount: latestEnrolledCount,
        isRemovable: facilityToUpdate ? facilityToUpdate.isRemovable : true,
        yearBuilt: updatedFacility.yearBuilt,
    };
};
export const convertImportFacilitiesRequest = (selectedFile, orgIdentity) => {
    return {
        File: selectedFile,
        OrganizationId: orgIdentity,
    };
};
