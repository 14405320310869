import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useOrganizationList } from '../../hooks/Organization/OrganizationListHook';
import { organizationSessionStore } from './OrganizationSessionHandler';
import { OrganizationSummaryContext } from './OrganizationSummaryContext';
export function OrganizationSummaryProvider({ children, context, }) {
    const [selectedOrganizationIdentity, setSelectedOrganizationIdentity] = useState();
    const [selectedOrgPhoto, setSelectedOrgPhoto] = useState(undefined);
    const { organizations, isLoading, onUpdateOrganization, onArchiveOrganization, } = useOrganizationList({
        context,
    });
    const { setSessionOrgIdentity } = organizationSessionStore();
    const selectOrganizationHandler = (identity) => {
        setSessionOrgIdentity(identity);
        setSelectedOrganizationIdentity(identity);
    };
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            const selectedOrg = organizations.find((org) => org.identity === selectedOrganizationIdentity);
            if (selectedOrg && selectedOrg.logoUrl) {
                setSelectedOrgPhoto(selectedOrg.logoUrl);
            }
            else {
                setSelectedOrgPhoto(undefined);
            }
        }
    }, [selectedOrganizationIdentity]);
    return (_jsx(OrganizationSummaryContext.Provider, { value: {
            selectedOrganizationIdentity,
            selectedOrgPhoto,
            organizations,
            onSelectOrganization: selectOrganizationHandler,
            isLoading,
            onUpdateOrganization,
            onArchiveOrganization,
        }, children: children }));
}
