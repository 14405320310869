var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { convertIFacilitytoIFacilitySummary } from '../../models/Facility/FacilityConversions';
import { useFacilityService } from '../../services/FacilityService';
import { useDependencies } from '../Dependencies/DependenciesContext';
export const useFacilityList = (selectedOrganizationIdentity) => {
    const { platformApi } = useDependencies();
    const { facility } = useFacilityService({ platformApi });
    const [isLoading, setIsLoading] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [facilityTemplate, setFacilityTemplate] = useState();
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetchFacilityTemplate(selectedOrganizationIdentity);
            fetchAllFacilities(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const fetchAllFacilities = (organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        return facility
            .fetchList(organizationIdentity)
            .then((res) => {
            setFacilities(res);
            return res;
        })
            .finally(() => setIsLoading(false));
    });
    const fetchFacilityTemplate = (organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        return facility
            .fetchTemplate(organizationIdentity)
            .then((res) => {
            setFacilityTemplate(res);
            return res;
        })
            .finally(() => setIsLoading(false));
    });
    const updateFacilityListOnDelete = (facilityIdentity) => {
        const facilityToDeleteIndex = facilities.findIndex((facility) => facility.identity === facilityIdentity);
        if (facilityToDeleteIndex) {
            facilities.splice(facilityToDeleteIndex, 1);
            setFacilities([...facilities]);
        }
    };
    const onUpdateFacility = (facility) => {
        setFacilities((prevFacilities) => {
            const facilityIndex = prevFacilities.findIndex((f) => f.identity === facility.identity);
            const updatedFacility = convertIFacilitytoIFacilitySummary(prevFacilities[facilityIndex], facility);
            if (facilityIndex !== -1) {
                const updatedFacilities = [...prevFacilities];
                updatedFacilities[facilityIndex] = updatedFacility;
                return updatedFacilities;
            }
            else {
                return [...prevFacilities, updatedFacility];
            }
        });
    };
    const deleteFacility = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return facility
            .delete(facilityIdentity)
            .then(() => updateFacilityListOnDelete(facilityIdentity));
    });
    const importFacilities = (selectedFile, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        // loading state in the component
        return facility.import(selectedFile, orgIdentity).then(() => {
            fetchAllFacilities(orgIdentity);
        });
    });
    return {
        isLoading,
        facilities,
        facilityTemplate,
        deleteFacility,
        fetchFacilities: fetchAllFacilities,
        fetchFacilityTemplate: fetchFacilityTemplate,
        onUpdateFacility,
        importFacilities,
    };
};
