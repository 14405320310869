import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, GridItem, HStack, Switch, Text } from '@chakra-ui/react';
import { DateInput } from '@frontend/design-system/components/Inputs/DateInput';
import { appColors } from '@frontend/design-system/theme/constants';
import theme from '@frontend/design-system/theme/theme';
import { getAppName } from '../../../config/helperFunctions';
import { AppAccessIcon } from '../../../sharedComponents/AppAccessIcon';
export const AppSelector = ({ appData, isEditable, onToggle, onDateChange, }) => {
    const appName = getAppName(appData.app);
    return (_jsxs(Grid, { templateColumns: {
            base: '1fr 1fr',
            lg: '1fr',
            xl: '2fr 1fr',
        }, border: isEditable ? `1px solid ${appColors.PRIM_LIGHT_GREY}` : 'none', borderRadius: isEditable ? '8px' : '0px', p: isEditable ? '10px' : '0px', w: '100%', gap: '10px', children: [_jsxs(GridItem, { display: 'flex', alignItems: 'center', maxW: '235px', justifyContent: {
                    base: 'flex-start',
                    lg: 'space-between',
                }, gap: {
                    base: '40px',
                    lg: '5px',
                    xl: '40px',
                }, children: [isEditable && (_jsx(Switch, { id: appName, isChecked: appData.enabled, onChange: (e) => onToggle(e.target.checked) })), _jsxs(HStack, { minW: { xl: '160px' }, children: [_jsx(AppAccessIcon, { app: appData.app, isActive: appData.enabled, fullLogo: true, customStyles: { width: '25px', height: '25px' } }), _jsx(Text, { color: !appData.enabled
                                    ? appColors.SEC_DARK_GRAY
                                    : appColors.TEXT_COLOR, fontSize: isEditable
                                    ? {
                                        base: '14px',
                                        lg: '10px',
                                        xl: '14px',
                                    }
                                    : '14px', whiteSpace: 'nowrap', children: appName })] })] }), _jsx(GridItem, { children: isEditable ? (_jsx(Box, { maxW: '150px', marginLeft: { base: 'auto', lg: '0px' }, margin: { lg: 'auto' }, children: _jsx(DateInput, { label: 'Access available until', data: appData.expiresOn
                            ? new Date(appData.expiresOn)
                                .toISOString()
                                .slice(0, 10)
                            : '', onInputChange: (e) => appData.enabled && onDateChange(e), customStyles: {
                            label: {
                                fontSize: '10px',
                                fontWeight: 700,
                                paddingLeft: '0px',
                                textAlign: 'center',
                            },
                            input: {
                                height: '25px',
                                fontSize: '14px',
                                backgroundColor: !appData.enabled
                                    ? appColors.LIGHT_GRAY_1
                                    : theme.colors.base.white,
                            },
                        }, isDisabled: !appData.enabled }) })) : (_jsxs(HStack, { justifyContent: { base: 'flex-end', lg: 'start' }, w: '100%', children: [_jsx(Text, { fontSize: '14px', color: appColors.PRIM_BLUE, whiteSpace: 'nowrap', children: "Access available until" }), _jsx(Text, { fontSize: '14px', color: !appData.enabled
                                ? appColors.SEC_DARK_GRAY
                                : appColors.TEXT_COLOR, whiteSpace: 'nowrap', minW: '85px', children: !appData.expiresOn
                                ? 'mm/dd/yyyy'
                                : new Date(appData.expiresOn).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }) })] })) })] }));
};
