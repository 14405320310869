import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, HStack, Text, Tooltip, VStack, } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { TextInput } from '@frontend/design-system/components/DynamicInput/TextInput';
import { BasicSelect } from '@frontend/design-system/components/Inputs/BasicSelect';
import { EulaMissingIcon } from '@frontend/design-system/theme/icons/logos/EulaMissingIcon';
import { isClientConciergePlus, isSystemAdmin, UserManagementContext, } from '@frontend/domain/contexts/Authorization/Roles';
import { toFriendlyOrgRole } from '@frontend/domain/conversion/roles';
import { IAppNames } from '@frontend/domain/models/App';
import { FacilityRoleEnum, OrganizationRoleEnum, SystemRole, } from '@frontend/domain/models/Roles';
import { convertIUserToUserAssignForm } from '@frontend/domain/models/User/UserConversions';
import { useEffect } from 'react';
import { Controller, useFieldArray, useWatch, } from 'react-hook-form';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { AssignUserApps } from '../../../sharedComponents/AssignUserApps';
import { UserOrgRolesTable } from './UserOrgRolesTable';
import { UserSystemRoleSelector } from './UserSystemRoleSelector';
const emptyOrganizationAssignmentForm = {
    name: '',
    role: OrganizationRoleEnum.EMPTY,
    facilityExceptions: [],
    appAccess: Object.values(IAppNames).map((app) => ({
        name: app,
        isActive: false,
    })),
};
export const AssignUserInputs = ({ form, validateUniqueEmail, editModal, isEulaAccepted, currentUser, userContext, organizations = [], userToEdit, removedOrgAssignments = [], setRemovedOrgAssignments, facilities, setSelectedOrgId, }) => {
    const { fields, append, update, remove } = useFieldArray({
        control: form.control,
        name: 'organizationAssignments',
    });
    const name = useWatch({ name: 'firstName', control: form.control });
    const lastName = useWatch({ name: 'lastName', control: form.control });
    const email = useWatch({ name: 'email', control: form.control });
    const systemRole = useWatch({ name: 'systemRole', control: form.control });
    useEffect(() => {
        const formData = form.getValues();
        if (fields.length === 0 &&
            userContext === UserManagementContext.ADMIN &&
            systemRole === SystemRole.USER) {
            form.setError('organizationAssignments', {
                message: 'User should have at least one assigned organization',
            });
        }
        else {
            form.clearErrors('organizationAssignments');
        }
        validateAppAccess(formData);
    }, [fields, form, systemRole, userContext]);
    const primaryContactOrgsAppAccess = (userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.primaryContactOrganizations.map((primaryContactOrg) => {
        var _a;
        const foundOrg = organizations.find((org) => org.identity === primaryContactOrg.identity);
        return Object.assign(Object.assign({}, primaryContactOrg), { appAccess: ((_a = foundOrg === null || foundOrg === void 0 ? void 0 : foundOrg.apps) === null || _a === void 0 ? void 0 : _a.map((app) => ({
                name: app.app,
                isActive: app.enabled,
            }))) || [] });
    })) || [];
    const getOrgNameDropdownOptions = (organizations) => {
        const assignedOrgs = fields.map((org) => org.name);
        return organizations
            .filter((org) => !assignedOrgs.includes(org.name))
            .map((org) => ({
            label: org.name,
            value: org.name,
        }));
    };
    const updateExceptions = (orgRole, orgIndex) => {
        const user = convertIUserToUserAssignForm(userToEdit, organizations);
        const orgAssigment = user.organizationAssignments[orgIndex];
        const defaultFacilityExceptions = orgAssigment === null || orgAssigment === void 0 ? void 0 : orgAssigment.facilityExceptions;
        if (orgAssigment &&
            (orgRole === OrganizationRoleEnum.MEMBER ||
                orgRole === OrganizationRoleEnum.VISITOR) &&
            orgRole !== orgAssigment.role) {
            const noneExceptions = defaultFacilityExceptions.filter((exception) => exception.role === FacilityRoleEnum.NONE);
            update(orgIndex, Object.assign(Object.assign({}, orgAssigment), { role: orgRole, facilityExceptions: noneExceptions }));
        }
        else if (orgAssigment && orgRole === orgAssigment.role) {
            update(orgIndex, Object.assign(Object.assign({}, orgAssigment), { role: orgRole, facilityExceptions: defaultFacilityExceptions }));
        }
        else if (orgAssigment) {
            const isValidEnum = Object.values(OrganizationRoleEnum).includes(orgRole);
            isValidEnum &&
                update(orgIndex, Object.assign(Object.assign({}, orgAssigment), { role: orgRole, facilityExceptions: [] }));
        }
        else {
            const isValidEnum = Object.values(OrganizationRoleEnum).includes(orgRole);
            isValidEnum &&
                update(orgIndex, Object.assign(Object.assign({}, fields[orgIndex]), { role: orgRole }));
        }
    };
    const getRoleDropdownOptions = (orgIdentity) => {
        return isClientConciergePlus(currentUser, orgIdentity)
            ? [
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.CLIENT_CONCIERGE),
                    value: OrganizationRoleEnum.CLIENT_CONCIERGE,
                },
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.LOCAL_ADMIN),
                    value: OrganizationRoleEnum.LOCAL_ADMIN,
                },
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.MEMBER),
                    value: OrganizationRoleEnum.MEMBER,
                },
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.VISITOR),
                    value: OrganizationRoleEnum.VISITOR,
                },
            ]
            : [
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.LOCAL_ADMIN),
                    value: OrganizationRoleEnum.LOCAL_ADMIN,
                },
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.MEMBER),
                    value: OrganizationRoleEnum.MEMBER,
                },
                {
                    label: toFriendlyOrgRole(OrganizationRoleEnum.VISITOR),
                    value: OrganizationRoleEnum.VISITOR,
                },
            ];
    };
    const validateAppAccess = (data) => {
        if (userContext === UserManagementContext.ORG) {
            data.organizationAssignments.forEach((assignment, index) => {
                const isAppAccessValid = assignment.appAccess.some((app) => app.isActive);
                if (!isAppAccessValid) {
                    form.setError(`organizationAssignments.${index}.appAccess`, {
                        message: 'User should have at least one active app',
                    });
                }
                else {
                    form.clearErrors(`organizationAssignments.${index}.appAccess`);
                }
            });
        }
    };
    const handleOrganizationChange = (number, selectedOrgName, organizations, formData) => {
        var _a, _b, _c, _d;
        const selectedOrganization = organizations.find((org) => org.name === selectedOrgName);
        const filteredAppAccess = (_b = (_a = formData.organizationAssignments[number]) === null || _a === void 0 ? void 0 : _a.appAccess) === null || _b === void 0 ? void 0 : _b.filter((app) => selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.apps.some((orgApp) => orgApp.app === app.name && orgApp.enabled)).map((app) => {
            return Object.assign(Object.assign({}, app), { isActive: true });
        });
        const prevRemovedOrgAssignment = removedOrgAssignments.find((org) => org.identity === (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.identity));
        prevRemovedOrgAssignment &&
            setRemovedOrgAssignments &&
            setRemovedOrgAssignments((prevOrgAssignments) => prevOrgAssignments.filter((orgAssignment) => orgAssignment.identity !==
                (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.identity)));
        return {
            identity: selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.identity,
            name: selectedOrgName,
            role: prevRemovedOrgAssignment
                ? prevRemovedOrgAssignment.role
                : (_c = formData.organizationAssignments[number]) === null || _c === void 0 ? void 0 : _c.role,
            facilityExceptions: prevRemovedOrgAssignment
                ? prevRemovedOrgAssignment.facilityExceptions
                : (_d = formData.organizationAssignments[number]) === null || _d === void 0 ? void 0 : _d.facilityExceptions,
            appAccess: prevRemovedOrgAssignment
                ? prevRemovedOrgAssignment.appAccess
                : filteredAppAccess,
        };
    };
    const isPrimaryContact = (orgAssignmentIdentity) => {
        return primaryContactOrgsAppAccess.some((org) => org.identity === orgAssignmentIdentity);
    };
    const handleRemove = (index, assignmentData) => {
        setRemovedOrgAssignments &&
            setRemovedOrgAssignments((prevItems) => [
                ...prevItems,
                assignmentData,
            ]);
        remove(index);
    };
    const data = fields.map((assignment, number) => {
        return Object.assign(Object.assign({ organization: assignment.name === '' ? (_jsx(Box, { w: '100%', maxH: 6, children: _jsx(Controller, { control: form.control, name: `organizationAssignments.${number}.name`, rules: { required: true }, render: ({ field }) => {
                        return (_jsx(BasicSelect, { value: field.value, options: getOrgNameDropdownOptions(organizations), onChange: (selectedOrgName) => {
                                field.onChange(selectedOrgName);
                                const updatedData = handleOrganizationChange(number, selectedOrgName, organizations, form.getValues());
                                update(number, updatedData);
                            }, height: 6, id: `organizationAssignments.${number}.name` }));
                    } }) })) : (_jsx(Tooltip, { label: assignment.name, openDelay: 750, children: _jsx(Text, { children: assignment.name }) })), role: (_jsx(Box, { w: '100%', maxH: 6, children: _jsx(Controller, { control: form.control, name: `organizationAssignments.${number}.role`, rules: { required: true }, render: ({ field }) => (_jsx(BasicSelect, { value: field.value, options: getRoleDropdownOptions(assignment.identity), onChange: (e) => {
                            field.onChange(e);
                            updateExceptions(e, number);
                        }, height: 6, required: true, id: `organizationAssignments.${number}.role` })) }) })), appAccess: (_jsx(AssignUserApps, { index: number, userAssignForm: form, validateAppAccess: validateAppAccess })) }, (userContext === UserManagementContext.ADMIN &&
            !isPrimaryContact(assignment.identity) && {
            orgAssignmentRemove: (_jsx(Button, { isIcon: true, icon: _jsx(BiSolidTrashAlt, {}), buttonType: 'customLink', onClick: () => handleRemove(number, form.getValues(`organizationAssignments.${number}`)) })),
        })), { roleValue: fields[number].role || assignment.role });
    });
    const { control, formState: { errors }, } = form;
    const hasRequiredUserInfo = !!(name && lastName && email && !errors.email);
    return (_jsxs(VStack, { spacing: 4, children: [_jsxs(HStack, { w: '100%', children: [_jsx(Controller, { name: 'firstName', control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'First name', editMode: true, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, disabled: editModal, required: true })) }), _jsx(Controller, { name: 'lastName', control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Last name', editMode: true, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, disabled: editModal, required: true })) }), editModal && (_jsx(Controller, { name: 'phoneNumber', control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Phone Number', editMode: true, inputType: 'text', data: field.value || '', errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, disabled: editModal })) }))] }), _jsxs(HStack, { w: '100%', children: [_jsx(Controller, { name: 'email', control: control, rules: {
                            required: true,
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Please enter a valid email address.',
                            },
                            validate: validateUniqueEmail,
                        }, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Email', editMode: true, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, disabled: editModal, required: true })) }), editModal && (_jsx(Controller, { name: 'jobTitle', control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextInput, { label: 'Title', editMode: true, inputType: 'text', data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, constraints: { maxLength: 80 }, disabled: editModal })) }))] }), !isEulaAccepted && editModal && (_jsxs(HStack, { w: '100%', display: 'flex', justify: 'flex-start', children: [_jsx(EulaMissingIcon, { boxSize: 6 }), _jsx(Text, { children: "This user has not accepted the EULA" })] })), _jsx(Divider, { width: '100%', my: 6, mx: 'auto', size: 'lg', color: 'rgba(192, 194, 193, 1)' }), userContext === UserManagementContext.ADMIN && (_jsxs(Flex, { w: '100%', justifyContent: 'space-between', alignItems: 'end', alignSelf: 'start', children: [_jsx(UserSystemRoleSelector, { form: form, fieldArray: { fields, append, remove }, currentUserRole: currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole, primaryContactOrgsAppAccess: primaryContactOrgsAppAccess, editModal: editModal, isDisabled: !hasRequiredUserInfo }), systemRole &&
                        !isSystemAdmin(systemRole) &&
                        hasRequiredUserInfo && (_jsx(Box, { display: 'flex', flex: '1', justifyContent: 'flex-end', children: _jsx(Button, { text: 'New organization', buttonType: 'secondary', icon: _jsx(AddIcon, { boxSize: '16px', w: '9px', h: '9px' }), iconPosition: 'left', onClick: () => append(emptyOrganizationAssignmentForm) }) }))] })), (userContext === UserManagementContext.ORG ||
                (userContext === UserManagementContext.ADMIN &&
                    systemRole &&
                    !isSystemAdmin(systemRole))) && (_jsx(UserOrgRolesTable, { data: data, form: form, facilities: facilities, setSelectedOrgId: setSelectedOrgId }))] }));
};
