var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDependencies } from '../../contexts/Dependencies/DependenciesContext';
import { convertOrganizationFormToIUpsertOrganization } from '../../models/Organization/OrganizationConversions';
import { useOrganizationService } from '../../services/OrganizationService';
export const useOrganization = ({ organizationIdentity, onUpdateOrganization, onArchiveOrganization, }) => {
    const { platformApi } = useDependencies();
    const { organization } = useOrganizationService({ platformApi });
    const [isLoading, setIsLoading] = useState(false);
    const [isTemplateLoading, setIsTemplateLoading] = useState(false);
    const [organizationDetails, setOrganizationDetails] = useState(undefined);
    const [orgTemplate, setOrgTemplate] = useState({
        verticalMarkets: [],
        appContexts: [],
    });
    useEffect(() => {
        if (!organizationIdentity)
            return;
        fetchDetails(organizationIdentity);
    }, [organizationIdentity]);
    useEffect(() => {
        initializeOrganization();
    }, []);
    const fetchDetails = (identity) => {
        setIsLoading(true);
        organization
            .fetchDetails(identity)
            .then((response) => {
            setOrganizationDetails(response);
        })
            .finally(() => setIsLoading(false));
    };
    const upsertOrganization = (org, image, logoName, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const convertedOrg = convertOrganizationFormToIUpsertOrganization(org, image, logoName);
        const updatedOrg = organization
            .update(convertedOrg, orgIdentity)
            .then((response) => {
            setOrganizationDetails(response);
            onUpdateOrganization && onUpdateOrganization(response);
            return response;
        })
            .finally(() => {
            setIsLoading(false);
        });
        return updatedOrg;
    });
    const archiveOrganization = (orgToArchive) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        organization
            .archiveOrganization(orgToArchive)
            .then((response) => {
            onArchiveOrganization && onArchiveOrganization(orgToArchive);
            return response;
        })
            .finally(() => {
            setIsLoading(false);
        });
    });
    const initializeOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsTemplateLoading(true);
        yield organization
            .initializeOrganization()
            .then((res) => {
            setOrgTemplate(res);
        })
            .catch(() => { })
            .finally(() => {
            setIsTemplateLoading(false);
        });
    });
    return {
        fetch: fetchDetails,
        isLoading,
        isTemplateLoading,
        organizationDetails,
        upsertOrganization,
        archiveOrganization,
        orgTemplate,
    };
};
