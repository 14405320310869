import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { Tabs } from '@frontend/design-system/components/Tabs/Tabs';
import { FacilityRoleEnum } from '@frontend/domain/models/Roles';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { OrganizationRoleEnum } from '../../../objects/UserData';
import { FacilityException } from './FacilityException';
export const FacilityExceptionsTabs = ({ selectedOrgRole, form, facilities, orgIndex, setSelectedOrgId, }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: `organizationAssignments.${orgIndex}.facilityExceptions`,
    });
    if (setSelectedOrgId) {
        const identity = Number(form.watch(`organizationAssignments.${orgIndex}.identity`));
        setSelectedOrgId(identity);
    }
    const [exceptionsTabs, setExceptionsTabs] = useState([]);
    const facilityExceptions = form.watch(`organizationAssignments.${orgIndex}.facilityExceptions`);
    const [isAddDisabled, setIsAddDisabled] = useState({} // Use role as key to store isAddDisabled state
    );
    useEffect(() => {
        const getRowCount = (role) => facilityExceptions.filter((exception) => exception && exception.role === role).length;
        const handleAddDisabled = (role) => { var _a; return (_a = isAddDisabled[role]) !== null && _a !== void 0 ? _a : false; };
        if (selectedOrgRole === OrganizationRoleEnum.VISITOR) {
            setExceptionsTabs([
                {
                    column: `Facilities I can edit (${getRowCount(FacilityRoleEnum.MEMBER)})`,
                    nestedComponent: (_jsx(FacilityException, { form: form, facilities: facilities, orgIndex: orgIndex, role: FacilityRoleEnum.MEMBER, activeIndex: activeIndex, setActiveIndex: setActiveIndex, isAddDisabled: handleAddDisabled(FacilityRoleEnum.MEMBER), setIsAddDisabled: (value) => setIsAddDisabled((prev) => (Object.assign(Object.assign({}, prev), { [FacilityRoleEnum.MEMBER]: value }))), fields: fields, append: append, remove: remove })),
                    customStyles: {
                        width: '100%',
                    },
                },
                {
                    column: `Facilities I cannot see (${getRowCount(FacilityRoleEnum.NONE)})`,
                    nestedComponent: (_jsx(FacilityException, { form: form, facilities: facilities, orgIndex: orgIndex, role: FacilityRoleEnum.NONE, activeIndex: activeIndex, setActiveIndex: setActiveIndex, isAddDisabled: handleAddDisabled(FacilityRoleEnum.NONE), setIsAddDisabled: (value) => setIsAddDisabled((prev) => (Object.assign(Object.assign({}, prev), { [FacilityRoleEnum.NONE]: value }))), fields: fields, append: append, remove: remove })),
                    customStyles: {
                        width: '100%',
                    },
                },
            ]);
        }
        else if (selectedOrgRole === OrganizationRoleEnum.MEMBER) {
            setExceptionsTabs([
                {
                    column: `Facilities I cannot edit (${getRowCount(FacilityRoleEnum.VISITOR)})`,
                    nestedComponent: (_jsx(FacilityException, { form: form, facilities: facilities, orgIndex: orgIndex, role: FacilityRoleEnum.VISITOR, activeIndex: activeIndex, setActiveIndex: setActiveIndex, isAddDisabled: handleAddDisabled(FacilityRoleEnum.VISITOR), setIsAddDisabled: (value) => setIsAddDisabled((prev) => (Object.assign(Object.assign({}, prev), { [FacilityRoleEnum.VISITOR]: value }))), fields: fields, append: append, remove: remove })),
                    customStyles: {
                        width: '100%',
                    },
                },
                {
                    column: `Facilities I cannot see (${getRowCount(FacilityRoleEnum.NONE)})`,
                    nestedComponent: (_jsx(FacilityException, { form: form, facilities: facilities, orgIndex: orgIndex, role: FacilityRoleEnum.NONE, activeIndex: activeIndex, setActiveIndex: setActiveIndex, isAddDisabled: handleAddDisabled(FacilityRoleEnum.NONE), setIsAddDisabled: (value) => setIsAddDisabled((prev) => (Object.assign(Object.assign({}, prev), { [FacilityRoleEnum.NONE]: value }))), fields: fields, append: append, remove: remove })),
                    customStyles: {
                        width: '100%',
                    },
                },
            ]);
        }
    }, [
        selectedOrgRole,
        form,
        orgIndex,
        facilities,
        facilityExceptions,
        activeIndex,
    ]);
    return (_jsx(Box, { w: '100%', display: 'flex', justifyContent: 'center', children: _jsx(Tabs, { tabs: exceptionsTabs, isFitted: false, style: { width: '100%' } }) }));
};
