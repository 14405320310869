import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Collapse, Td, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { variants } from '../../../theme/theme';
import { useTableAccordion } from '../TableAccordionContext';
export function TableRow({ data, hasSeeMoreColumns, totalColumns, dropdown, }) {
    const { isOpen, togglePanel } = useTableAccordion();
    const isExpanded = isOpen(data.index);
    const hasSeeMoreColumn = data.original.seeMore !== undefined;
    // This is to enable the animation effect that we get from the collapse component
    // we need to wait for the panel to be put on the DOM before we can animate it
    // or else it will just appear without the animation
    const [isFullyExpanded, setIsFullyExpanded] = React.useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFullyExpanded(isExpanded);
        }, 100);
        return () => {
            clearTimeout(timer);
        };
    }, [isExpanded]);
    return (_jsxs(_Fragment, { children: [_jsxs(Tr, { borderRadius: '50px', h: '65px', children: [data.getVisibleCells().map((cell, i, cells) => {
                        return (_jsx(Td, Object.assign({}, (i === 0 && {
                            borderRadius: '50px 0 0 50px',
                            borderLeft: '1px solid',
                        }), (i === cells.length - 1 &&
                            !hasSeeMoreColumns && {
                            borderRadius: '0 50px 50px 0',
                            borderRight: '1px solid',
                        }), (i === 0 &&
                            isExpanded && {
                            borderRadius: '15px 0 0 0',
                            borderLeft: '1px solid',
                        }), { borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'secondaryDarkGray.300', style: data.original.style, children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id));
                    }), hasSeeMoreColumns && (_jsx(Td, { borderRadius: isExpanded ? '0 15px 0px 0' : '0 50px 50px 0', border: '1px solid', borderLeft: 'none', borderColor: 'secondaryDarkGray.300', w: '0%' //todo this is a weird hack... why?
                        , style: data.original.style, children: _jsx(Button, { width: 'fit-content', "data-testid": 'see_more', variant: variants.primaryTransparent, visibility: hasSeeMoreColumn ? 'visible' : 'hidden', onClick: () => togglePanel(data.index), padding: '0px', children: dropdown
                                ? dropdown(isExpanded)
                                : isExpanded
                                    ? 'See less'
                                    : 'See more' }) }))] }), hasSeeMoreColumn && isExpanded && (_jsx(Tr, { h: isFullyExpanded ? 'auto' : 0, position: 'relative', top: '-10px', children: _jsx(Td, { colSpan: totalColumns + 1, borderRadius: '0 0 15px 15px', border: '1px solid', borderBottom: '1px solid', borderColor: isExpanded ? 'secondaryDarkGray.300' : 'transparent', children: _jsx(Collapse, { in: isFullyExpanded, children: _jsx(Box, { py: '15px', px: '20px', children: data.original.seeMore }) }) }) }))] }));
}
