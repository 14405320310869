import { useOrganizationSummaries } from '../Organization/OrganizationSummaryContext';
import { useOrganization } from '../../hooks/Organization/OrganizationHook';
import { useEffect, useState } from 'react';
export const useOrgAccess = (app) => {
    const { selectedOrganizationIdentity, isLoading: isOrgSummaryLoading, organizations, } = useOrganizationSummaries();
    const { isLoading: isOrgDetailsLoading, organizationDetails, fetch, } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const [appAccess, setAppAccess] = useState(false);
    // If the organization was updated in another component, we need to
    // re-fetch the organization's details to trigger a re-render
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetch(selectedOrganizationIdentity);
        }
    }, [organizations, selectedOrganizationIdentity]);
    useEffect(() => {
        if (!isOrgDetailsLoading) {
            organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.apps.forEach((appDetails) => {
                if (appDetails.app === app) {
                    setAppAccess(appDetails.enabled);
                }
            });
        }
    }, [
        isOrgDetailsLoading,
        isOrgSummaryLoading,
        organizations,
        selectedOrganizationIdentity,
    ]);
    return appAccess;
};
