var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertFacilityDetailResponseToIFacility, convertIConfigureFacilityToFacilityFormData, } from '../models/Facility/FacilityConversions';
import { convertOrganizationResponseToIOrganizationDetails, convertOrganizationSummaryResponseToOrganizationSummary, convertOrganizationTemplateResponseToIOrganizationInitial, convertOrganizationToRequest, } from '../models/Organization/OrganizationConversions';
export const useOrganizationService = ({ platformApi, }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchDetails = (identity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.getApiV1OrganizationLoad(identity)).then((org) => convertOrganizationResponseToIOrganizationDetails(org));
    });
    const fetchOrganizationList = (appContext) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.getApiV1OrganizationList(appContext), {
            error: {
                label: 'Error occurred retrieving User Orgs',
            },
            success: { enabled: false },
        }).then((res) => res.map((org) => convertOrganizationSummaryResponseToOrganizationSummary(org)));
    });
    const upsertOrganization = (org, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.postApiV1OrganizationConfigure(convertOrganizationToRequest(org, orgIdentity)), {
            error: {
                label: `Error occurred updating Organization: ${org.nameOfOrg}`,
            },
            success: {
                enabled: true,
                label: `Updated Organization: ${org.nameOfOrg}`,
            },
        }).then((res) => convertOrganizationResponseToIOrganizationDetails(res));
    });
    const archiveOrganization = (orgToArchive) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.postApiV1OrganizationArchive({
            organizationIds: [orgToArchive.identity],
        }), {
            error: {
                label: 'Error occurred archiving the Organizations',
            },
            success: {
                enabled: true,
                label: `Archived Organizations: ${orgToArchive.nameOfOrg}`,
            },
        }).then();
    });
    // TODO: move to facility service layer when applying to piq
    const updateFacility = (updatedFacility, organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        const facilityRequest = organizationIdentity &&
            convertIConfigureFacilityToFacilityFormData(updatedFacility, organizationIdentity);
        return handlePlatformResponse(platformApi.facility.postApiV1FacilityConfigure(Object.assign({}, facilityRequest)), {
            error: {
                label: `Error occurred updating Facility: ${updatedFacility.name}`,
            },
            success: {
                enabled: false,
            },
        }).then(convertFacilityDetailResponseToIFacility);
    });
    const initializeOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.organization.getApiV1OrganizationInitialize(), {
            error: {
                label: 'Error occurred initializing the organization',
            },
            success: {
                enabled: false,
            },
        }).then((res) => {
            return convertOrganizationTemplateResponseToIOrganizationInitial(res);
        });
    });
    return {
        organization: {
            fetchList: fetchOrganizationList,
            fetchDetails: fetchDetails,
            update: upsertOrganization,
            updateFacility: updateFacility,
            archiveOrganization: archiveOrganization,
            initializeOrganization: initializeOrganization,
        },
    };
};
