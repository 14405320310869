import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SystemRoleEnum } from '@/objects/UserData';
import { Box, Tooltip } from '@chakra-ui/react';
import { PopoverMenu, } from '@frontend/design-system/components/ActionMenu/PopoverMenu';
import { Table } from '@frontend/design-system/components/Table/Table';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { useAllOrgAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { consolidateName } from '@frontend/domain/conversion/NameConversions';
import { toFriendlySystemRole } from '@frontend/domain/conversion/roles';
import { IAppNames } from '@frontend/domain/models/App';
import { SystemRole } from '@frontend/domain/models/Roles';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { DeleteModal } from '../../../sharedComponents/DeleteModal';
import { AddOrEditAdminUserModal } from '../SystemAdmin/EditAdminModal';
import { UserManagementTableHeader } from './UserManagementTableHeader';
const columnHelper = createColumnHelper();
export const UserManagementTable = ({ org, userDirectory, canResendInvite, resendInvite, addNewUser, assignUserAccess, onOffboardUser, canOffboardUser, currentUser, organizations, facilities, fetchFacilities, openWithUser, closeWithUser, openUserDisclosure, deleteUserDisclosure, userToEdit, }) => {
    const canResendInvitation = canResendInvite(UserManagementContext.ADMIN, org === null || org === void 0 ? void 0 : org.identity);
    const canEditUser = (userToEditRole, currentUserRole) => {
        if (currentUserRole === SystemRole.ORGANIZATION_APPROVER &&
            userToEditRole === SystemRole.SUPER_ADMINISTRATOR) {
            return false;
        }
        return true;
    };
    const checkOrgAccess = (appName) => {
        return org === null || org === void 0 ? void 0 : org.apps.some((app) => app.app === appName && app.enabled === true);
    };
    const orgAppAccess = {
        hasReportAccess: checkOrgAccess(IAppNames.REPORTS) ? true : false,
        hasFCAAccess: checkOrgAccess(IAppNames.FCA) ? true : false,
        hasPlannerAccess: checkOrgAccess(IAppNames.PLANNER) ? true : false,
        hasPIQAccess: checkOrgAccess(IAppNames.PIQ) ? true : false,
    };
    const availableApps = (org === null || org === void 0 ? void 0 : org.apps.map((app) => app.app)) || [];
    const mapToUserManagementTable = (systemUser) => {
        const { [IAppNames.REPORTS]: userHasReportsAccess, [IAppNames.FCA]: userHasFCAAccess, [IAppNames.PLANNER]: userHasPlannerAccess, [IAppNames.PIQ]: userHasPIQAccess, } = useAllOrgAppAccess(systemUser, org === null || org === void 0 ? void 0 : org.identity, (org === null || org === void 0 ? void 0 : org.apps) || []);
        const userAppAccess = {
            hasReportAccess: userHasReportsAccess,
            hasFCAAccess: userHasFCAAccess,
            hasPlannerAccess: userHasPlannerAccess,
            hasPIQAccess: userHasPIQAccess,
        };
        return {
            identity: systemUser.identity,
            name: `${systemUser.firstName} ${systemUser.lastName}`,
            emailAddress: systemUser.emailAddress,
            jobTitle: systemUser.jobTitle,
            isActive: systemUser.isActive,
            userAppAccess: userAppAccess,
            orgAppAccess: orgAppAccess,
            organizationRoles: systemUser.organizationRoles,
            systemRole: systemUser.systemRole,
            canDelete: canOffboardUser(UserManagementContext.ADMIN, systemUser),
        };
    };
    // Function to sort org roles
    const organizationRoleSortingFn = (rowA, rowB, columnId) => {
        const a = rowA.getValue(columnId);
        const b = rowB.getValue(columnId);
        const rolePriority = {
            [SystemRoleEnum.USER]: 0,
            [SystemRoleEnum.ORG_APPROVER]: 1,
            [SystemRoleEnum.SUPER_ADMIN]: 2,
        };
        return rolePriority[a] - rolePriority[b];
    };
    const columns = [
        columnHelper.accessor('name', {
            cell: (info) => {
                const { name } = info.row.original;
                return (_jsx(Tooltip, { label: name, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'name', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: name }) }));
            },
            header: 'Name',
        }),
        columnHelper.accessor('systemRole', {
            cell: (info) => {
                const { systemRole } = info.row.original;
                return (_jsx(Tooltip, { label: 'systemRole', openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'systemRole', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: toFriendlySystemRole(systemRole) }) }));
            },
            header: 'Role',
            sortingFn: organizationRoleSortingFn,
        }),
        columnHelper.accessor('jobTitle', {
            cell: (info) => {
                const { jobTitle } = info.row.original;
                return (_jsx(Tooltip, { label: jobTitle, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'jobTitle', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: jobTitle }) }));
            },
            header: 'Title',
        }),
        columnHelper.accessor('emailAddress', {
            cell: (info) => {
                const { emailAddress } = info.row.original;
                return (_jsx(Tooltip, { label: emailAddress, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'emailAddress', w: '15em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: emailAddress }) }));
            },
            header: 'Email',
        }),
        columnHelper.accessor('organizationRoles', {
            cell: (info) => {
                const { organizationRoles, systemRole } = info.row.original;
                return (_jsx(Tooltip, { label: 'organizationRoles', openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'organizationRoles', w: '5em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: systemRole !== SystemRole.ORGANIZATION_APPROVER &&
                            systemRole !== SystemRole.SUPER_ADMINISTRATOR
                            ? organizationRoles.length
                            : '-' }) }));
            },
            header: '# of organizations',
        }),
        columnHelper.display({
            id: 'editColumn',
            cell: (info) => {
                const { identity, canDelete, isActive, systemRole } = info.row.original;
                const canResendInvite = !isActive && canResendInvitation;
                const items = [
                    {
                        label: 'Edit',
                        onClick: () => openWithUser(openUserDisclosure.onOpen, identity),
                        isDisabled: !canEditUser(systemRole, currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole),
                    },
                    {
                        label: 'Deactivate',
                        onClick: () => openWithUser(deleteUserDisclosure.onOpen, identity),
                        isDisabled: !canDelete,
                    },
                    {
                        label: 'Resend Invite',
                        onClick: () => resendInvite(identity),
                        isDisabled: !canResendInvite,
                    },
                ];
                const menuItems = items.filter((item) => !item.isDisabled);
                return (_jsx(_Fragment, { children: menuItems.length > 0 && (_jsx(PopoverMenu, { menuItems: menuItems })) }));
            },
            header: '',
        }),
    ];
    const tableData = userDirectory.map((systemUser) => {
        const UserManagementTable = mapToUserManagementTable(systemUser);
        const bgColor = '';
        return Object.assign(Object.assign({}, UserManagementTable), { style: {
                backgroundColor: bgColor,
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingRight: '5px',
            } });
    });
    return (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(Table, { maxHeight: '85vh', data: tableData, columns: columns, header: _jsx(UserManagementTableHeader, {}), initialSort: [{ id: 'name', desc: false }] }) }), _jsx(AddOrEditAdminUserModal, { isOpen: openUserDisclosure.isOpen, onClose: () => closeWithUser(openUserDisclosure.onClose), userDirectory: userDirectory, availableApps: availableApps, addNewUser: addNewUser, assignUserAccess: assignUserAccess, userToEdit: userToEdit, organizations: organizations, currentUser: currentUser, facilities: facilities, fetchFacilities: fetchFacilities }), userToEdit && (_jsx(DeleteModal, { isOpen: deleteUserDisclosure.isOpen, onClose: () => closeWithUser(deleteUserDisclosure.onClose), deleteItem: () => {
                    onOffboardUser(userToEdit);
                    closeWithUser(deleteUserDisclosure.onClose);
                }, title: 'Deactivate User', subheading: consolidateName({
                    firstName: userToEdit.firstName,
                    lastName: userToEdit.lastName,
                }), description: 'This user will no longer be able to access mySiteIQ. If this user should retain access do not deactivate this account.', buttonText: 'Deactivate' }))] }));
};
