var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { OrganizationRoleEnum } from '@frontend/domain/models/Roles';
import { convertIUserToUserAssignForm } from '@frontend/domain/models/User/UserConversions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AssignUserInputs } from './AssignUserInputs';
export const AddOrEditOrgUserModal = ({ isOpen, onClose, orgUsers, currentOrg, availableApps, addNewUser, assignUserAccess, userToEdit, currentUser, facilities, fetchFacilities, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedOrgId, setSelectedOrgId] = useState(undefined);
    useEffect(() => {
        if (selectedOrgId && fetchFacilities) {
            fetchFacilities(selectedOrgId);
        }
    }, [selectedOrgId]);
    const createUpdateUserForm = () => {
        const user = convertIUserToUserAssignForm(userToEdit, [currentOrg], currentOrg.identity);
        user.phoneNumber = userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.phoneNumber;
        user.jobTitle = userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.jobTitle;
        return user;
    };
    const createNewUserForm = () => {
        const user = convertIUserToUserAssignForm(undefined, []);
        user.organizationAssignments = [
            {
                identity: currentOrg.identity,
                name: currentOrg.name,
                role: OrganizationRoleEnum.EMPTY,
                facilityExceptions: [],
                appAccess: availableApps.map((app) => ({
                    name: app,
                    isActive: true,
                })),
            },
        ];
        return user;
    };
    const userUseForm = useForm({
        values: userToEdit ? createUpdateUserForm() : createNewUserForm(),
        mode: 'onChange',
    });
    const { handleSubmit, reset, formState: { isValid, errors }, } = userUseForm;
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        if (userToEdit) {
            assignUserAccess(data)
                .then(() => {
                onClose();
                reset();
            })
                .finally(() => setIsSubmitting(false));
        }
        else {
            addNewUser(data)
                .then(() => {
                onClose();
                reset();
            })
                .finally(() => setIsSubmitting(false));
        }
    });
    const validateUserEmail = (value) => {
        if (userToEdit) {
            return true;
        }
        return (!orgUsers.some((user) => user.emailAddress === value) ||
            'This user already exists within this organization. Please edit their existing profile.');
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
            reset();
        }, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { maxW: '80%', maxH: '80%', children: [_jsx(ModalHeader, { padding: 6, children: userToEdit ? 'Edit User' : 'New User' }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(AssignUserInputs, { form: userUseForm, validateUniqueEmail: validateUserEmail, editModal: userToEdit ? true : false, isEulaAccepted: userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.isEulaAccepted, currentUser: currentUser, userContext: UserManagementContext.ORG, facilities: facilities, setSelectedOrgId: setSelectedOrgId, userToEdit: userToEdit, organizations: [currentOrg] }), _jsxs(HStack, { paddingY: 6, w: '100%', justifyContent: 'space-between', children: [_jsx(Button, { variant: variants.transparentBlueBtn, ml: 3, onClick: () => {
                                                onClose();
                                                reset();
                                            }, children: "Cancel" }), _jsx(Button, { type: 'submit', variant: variants.blueBtn, isDisabled: !isValid ||
                                                !!errors.organizationAssignments ||
                                                isSubmitting, children: "Save" })] })] }) })] })] }));
};
