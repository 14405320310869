import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddOrEditOrgUserModal } from '@/pages/SystemAdmin/UserManagement/AddOrEditOrgUserModal';
import { DeleteModal } from '@/sharedComponents/DeleteModal';
import { UserApps } from '@/sharedComponents/UserApps';
import { Box, Tooltip, useDisclosure } from '@chakra-ui/react';
import { PopoverMenu } from '@frontend/design-system/components/ActionMenu/PopoverMenu';
import { Table } from '@frontend/design-system/components/Table/Table';
import { UserManagementContext } from '@frontend/domain/contexts/Authorization/Roles';
import { useAllOrgAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { consolidateName } from '@frontend/domain/conversion/NameConversions';
import { toFriendlyOrgRole } from '@frontend/domain/conversion/roles';
import { IAppNames } from '@frontend/domain/models/App';
import { OrganizationRoleEnum, } from '@frontend/domain/models/Roles';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { MyUsersTableHeader } from './MyUsersTableHeader';
const columnHelper = createColumnHelper();
export const MyUsersTable = ({ org, orgUsers, 
// canEditOrgUser,
canAddOrgUser, canResendInvite, resendInvite, canOffboard, canEditUser, assignUserAccess, addNewUser, onOffboardUser, currentUser, facilities, fetchFacilities, }) => {
    const [userToEdit, setUserToEdit] = useState();
    const { isOpen: isOpenAddUser, onOpen: onOpenAddUser, onClose: onCloseAddUser, } = useDisclosure();
    const { isOpen: isOpenDeleteUser, onOpen: onOpenDeleteUser, onClose: onCloseDeleteUser, } = useDisclosure();
    const openWithUser = (onOpen, identity) => {
        const user = orgUsers.find((user) => user.identity === identity);
        setUserToEdit(user);
        onOpen();
    };
    const closeWithUser = (onClose) => {
        setUserToEdit(undefined);
        onClose();
    };
    const canResendInvitation = canResendInvite(UserManagementContext.ORG, org === null || org === void 0 ? void 0 : org.identity);
    const checkOrgAccess = (appName) => {
        return org === null || org === void 0 ? void 0 : org.apps.some((app) => app.app === appName && app.enabled === true);
    };
    const orgAppAccess = {
        hasReportAccess: checkOrgAccess(IAppNames.REPORTS) ? true : false,
        hasFCAAccess: checkOrgAccess(IAppNames.FCA) ? true : false,
        hasPlannerAccess: checkOrgAccess(IAppNames.PLANNER) ? true : false,
        hasPIQAccess: checkOrgAccess(IAppNames.PIQ) ? true : false,
    };
    const mapToMyUsersTable = (orgUser) => {
        const { [IAppNames.REPORTS]: userHasReportsAccess, [IAppNames.FCA]: userHasFCAAccess, [IAppNames.PLANNER]: userHasPlannerAccess, [IAppNames.PIQ]: userHasPIQAccess, } = useAllOrgAppAccess(orgUser, org === null || org === void 0 ? void 0 : org.identity, (org === null || org === void 0 ? void 0 : org.apps) || []);
        const userAppAccess = {
            hasReportAccess: userHasReportsAccess,
            hasFCAAccess: userHasFCAAccess,
            hasPlannerAccess: userHasPlannerAccess,
            hasPIQAccess: userHasPIQAccess,
        };
        const currentOrgRole = orgUser.organizationRoles.find((role) => role.organizationIdentity === (org === null || org === void 0 ? void 0 : org.identity));
        return {
            identity: orgUser.identity,
            name: `${orgUser.firstName} ${orgUser.lastName}`,
            emailAddress: orgUser.emailAddress,
            jobTitle: orgUser.jobTitle,
            userAppAccess: userAppAccess,
            orgAppAccess: orgAppAccess,
            organizationRole: currentOrgRole,
            canDelete: canOffboard(UserManagementContext.ORG, orgUser),
            isActive: orgUser.isActive,
            canEdit: canEditUser(UserManagementContext.ORG, orgUser, org === null || org === void 0 ? void 0 : org.identity),
        };
    };
    // Function to sort org roles
    const organizationRoleSortingFn = (rowA, rowB, columnId) => {
        const a = rowA.getValue(columnId);
        const b = rowB.getValue(columnId);
        const rolePriority = {
            [OrganizationRoleEnum.NONE]: 0,
            [OrganizationRoleEnum.VISITOR]: 1,
            [OrganizationRoleEnum.MEMBER]: 2,
            [OrganizationRoleEnum.LOCAL_ADMIN]: 3,
            [OrganizationRoleEnum.CLIENT_CONCIERGE]: 4,
        };
        return rolePriority[a.role] - rolePriority[b.role];
    };
    const columns = [
        columnHelper.accessor('name', {
            cell: (info) => {
                const { name } = info.row.original;
                return (_jsx(Tooltip, { label: name, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'name', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: name }) }));
            },
            header: 'Name',
        }),
        columnHelper.accessor('jobTitle', {
            cell: (info) => {
                const { jobTitle } = info.row.original;
                return (_jsx(Tooltip, { label: jobTitle, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'jobTitle', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: jobTitle }) }));
            },
            header: 'Title',
        }),
        columnHelper.accessor('emailAddress', {
            cell: (info) => {
                const { emailAddress } = info.row.original;
                return (_jsx(Tooltip, { label: emailAddress, openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'emailAddress', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: emailAddress }) }));
            },
            header: 'Email',
        }),
        columnHelper.accessor('organizationRole', {
            cell: (info) => {
                const { organizationRole } = info.row.original;
                return (_jsx(Tooltip, { label: 'organizationRole', openDelay: 750, children: _jsx(Box, { display: '-webkit-box', "data-testid": 'organizationRole', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }, children: toFriendlyOrgRole(organizationRole === null || organizationRole === void 0 ? void 0 : organizationRole.role) }) }));
            },
            header: 'organization Role',
            sortingFn: organizationRoleSortingFn,
        }),
        columnHelper.display({
            cell: (info) => {
                const { userAppAccess, orgAppAccess } = info.row.original;
                return (_jsx(UserApps, { userAppAccess: userAppAccess, orgAppAccess: orgAppAccess }));
            },
            header: 'Access',
        }),
        columnHelper.display({
            id: 'editColumn',
            cell: (info) => {
                const { canDelete, isActive, canEdit } = info.row.original;
                const rowData = info.row.original;
                const menuItems = [];
                canEdit &&
                    menuItems.push({
                        label: 'Edit',
                        onClick: () => {
                            openWithUser(onOpenAddUser, rowData.identity);
                        },
                    });
                canDelete &&
                    menuItems.push({
                        label: 'Remove',
                        onClick: () => openWithUser(onOpenDeleteUser, rowData.identity),
                    });
                canResendInvitation &&
                    !isActive &&
                    menuItems.push({
                        label: 'Resend Invitation',
                        onClick: () => resendInvite(rowData.identity),
                    });
                return (_jsx(_Fragment, { children: menuItems.length > 0 && (_jsx(PopoverMenu, { menuItems: menuItems })) }));
            },
            header: '',
        }),
    ];
    const tableData = orgUsers.map((orgUser) => {
        const myUsersTable = mapToMyUsersTable(orgUser);
        const bgColor = '';
        return Object.assign(Object.assign({}, myUsersTable), { style: {
                backgroundColor: bgColor,
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingRight: '5px',
            } });
    });
    const availableApps = (org === null || org === void 0 ? void 0 : org.apps.filter((app) => app.enabled === true).map((app) => app.app)) || [];
    const onDelete = () => {
        userToEdit &&
            onOffboardUser(userToEdit).then(() => onCloseDeleteUser());
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(Table, { maxHeight: '85vh', data: tableData, columns: columns, header: _jsx(MyUsersTableHeader, { canAddOrgUser: canAddOrgUser, onOpenAddUser: 
                        // ensure no user to edit
                        () => openWithUser(onOpenAddUser, undefined) }), initialSort: [{ id: 'name', desc: false }] }) }), org && (_jsx(AddOrEditOrgUserModal, { isOpen: isOpenAddUser, onClose: () => closeWithUser(onCloseAddUser), orgUsers: orgUsers, currentOrg: org, availableApps: availableApps, addNewUser: addNewUser, assignUserAccess: assignUserAccess, userToEdit: userToEdit, currentUser: currentUser, facilities: facilities, fetchFacilities: fetchFacilities })), userToEdit && (_jsx(DeleteModal, { isOpen: isOpenDeleteUser, onClose: () => closeWithUser(onCloseDeleteUser), deleteItem: onDelete, title: 'Remove User', subheading: consolidateName({
                    firstName: userToEdit.firstName,
                    lastName: userToEdit.lastName,
                }), description: 'This user will no longer be able to access this\n\t\t\t\t\t\torganization. If this is the only organization the user\n\t\t\t\t\t\tbelongs to, their account will be deactivated upon\n\t\t\t\t\t\tremoval.', buttonText: 'Remove' }))] }));
};
