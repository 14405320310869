import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { BiSolidTrashAlt } from 'react-icons/bi';
export const FacilityExceptionInput = ({ form, index, removeException, allFacilities, availableFacilities, editMode, onEdit, orgIndex, onSelectChange, facility, }) => {
    const [facilityId, setFacilityId] = useState(facility.identity);
    return (_jsx(Box, { w: '100%', onClick: !editMode ? onEdit : undefined, children: _jsx(Controller, { name: `organizationAssignments.${orgIndex}.facilityExceptions.${index}.identity`, control: form.control, rules: { required: true }, render: ({ field }) => {
                const selectedFacility = allFacilities === null || allFacilities === void 0 ? void 0 : allFacilities.find((facility) => facility.identity === Number(facilityId));
                const value = allFacilities === null || allFacilities === void 0 ? void 0 : allFacilities.find((facility) => facility.identity === facilityId);
                return editMode ? (_jsx(Form.SearchableDropdown, { "data-testId": 'facility-dropdown', label: '', selectFrom: availableFacilities
                        ? availableFacilities === null || availableFacilities === void 0 ? void 0 : availableFacilities.map((facility) => ({
                            label: facility.name,
                            value: facility.identity,
                        }))
                        : [], value: value && {
                        value: value.identity,
                        label: value.name,
                    }, onChange: (e) => {
                        field.onChange(Number(e === null || e === void 0 ? void 0 : e.value));
                        onSelectChange(Number(e === null || e === void 0 ? void 0 : e.value));
                        setFacilityId(Number(e === null || e === void 0 ? void 0 : e.value));
                    } })) : (_jsxs(Box, { w: '100%', display: 'flex', justifyContent: 'space-between', children: [_jsx(Text, { alignSelf: 'center', children: selectedFacility ? selectedFacility.name : '' }), _jsx(Button, { text: 'delete exception', onClick: removeException, buttonType: 'customLink', isIcon: true, icon: _jsx(BiSolidTrashAlt, {}), iconPosition: 'left', size: 'small' })] }));
            } }) }));
};
