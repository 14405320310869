export var PropertySizes;
(function (PropertySizes) {
    PropertySizes["SQFT"] = "SqFt";
    PropertySizes["KEYS"] = "Keys";
    PropertySizes["UNITS"] = "Units";
})(PropertySizes || (PropertySizes = {}));
// Should be converted to a boolean
export const FriendlyYesNoValues = {
    Yes: 'Yes',
    No: 'No',
};
export const FriendlyYesNoOptions = Object.values(FriendlyYesNoValues);
