var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseReportUrlMsiqApi = '/Report';
export default class OrgService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    userReports(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.getWithQueryParams(`/api/v1${baseReportUrlMsiqApi}/list/${orgIdentity}`);
        });
    }
    fetchReport(reportIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.getWithQueryParams(`/api/v1${baseReportUrlMsiqApi}/view/${reportIdentity}`);
        });
    }
    assessmentRefresh(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1/Assessment/refresh/${orgIdentity}`);
        });
    }
    registerReport(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${baseReportUrlMsiqApi}/register`, data);
        });
    }
    removeReport(reportId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.post(`/api/v1${baseReportUrlMsiqApi}/remove/${reportId}`);
        });
    }
}
