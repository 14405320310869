var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { consolidateName } from '../../conversion/NameConversions';
import { convertUserAssignFormToIAssignUserAccess, convertUserAssignFormToINewUser, mapOganizationAssignmentFormToIOrganizationRole, } from '../../models/User/UserConversions';
import { useUserService } from '../../services/UserService';
import { useDependencies } from '../Dependencies/DependenciesContext';
export const useUserDirectory = ({ requiresOrg, organizationIdentity, filterByPrimaryContact, }) => {
    const { platformApi } = useDependencies();
    const { user: { fetchList, assign, invite, offboard, resendInvite }, } = useUserService({ platformApi });
    // initialize with true due to fetch in useEffect
    const [isLoading, setIsLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const fetchOrgUsersList = (organizationIdentity) => {
        setIsLoading(true);
        fetchList(organizationIdentity, filterByPrimaryContact)
            .then(setUserList)
            .finally(() => setIsLoading(false));
    };
    useEffect(() => {
        if (requiresOrg && organizationIdentity !== undefined) {
            fetchOrgUsersList(organizationIdentity);
        }
        else if (!requiresOrg) {
            fetchOrgUsersList();
        }
    }, [organizationIdentity]); // ensures only fetches list once on initialization or if a new org is selected
    const updateUserInDirectory = (updatedUser) => {
        const updatedList = [...userList];
        const existingUserIndex = updatedList.findIndex((user) => user.identity === updatedUser.identity);
        if (existingUserIndex < 0) {
            updatedList.push(updatedUser);
        }
        else {
            updatedList.splice(existingUserIndex, 1, updatedUser);
        }
        setUserList(updatedList);
        return updatedUser;
    };
    const assignUserAccess = (userAssignForm, orgsToOffboard) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const userName = consolidateName({
            firstName: userAssignForm.firstName,
            lastName: userAssignForm.lastName,
        });
        const requestData = convertUserAssignFormToIAssignUserAccess(userAssignForm);
        return assign(userName, requestData)
            .then((updatedUser) => {
            if (orgsToOffboard && orgsToOffboard.length > 0) {
                const exsitingOrgsToOffboard = orgsToOffboard.filter((removedOrg) => updatedUser.organizationRoles.some((savedOrg) => removedOrg.identity ===
                    savedOrg.organizationIdentity));
                exsitingOrgsToOffboard.forEach((assignment) => {
                    offboardUser(updatedUser, assignment.identity, userAssignForm.organizationAssignments);
                });
            }
            else {
                updateUserInDirectory(updatedUser);
            }
        })
            .finally(() => setIsLoading(false));
    });
    const offboardUser = (user, organizationIdentity, updatedOrgAssignments) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const userToOffboard = {
            userIdentity: user.identity,
            organizationIdentity: organizationIdentity,
        };
        return offboard([userToOffboard])
            .then(() => {
            var _a;
            const hasRemainingOrgRoles = (_a = updatedOrgAssignments === null || updatedOrgAssignments === void 0 ? void 0 : updatedOrgAssignments.some((role) => role.identity !== organizationIdentity)) !== null && _a !== void 0 ? _a : false;
            if (hasRemainingOrgRoles) {
                const updatedUser = Object.assign(Object.assign({}, user), { organizationRoles: (updatedOrgAssignments || []).map(mapOganizationAssignmentFormToIOrganizationRole) });
                updateUserInDirectory(updatedUser);
            }
            else {
                const updatedList = userList.filter((user) => userToOffboard.userIdentity !== user.identity);
                setUserList(updatedList);
            }
        })
            .finally(() => setIsLoading(false));
    });
    /** This should convert UserAssignForm to INewUser and call invite from service layer */
    const addNewUser = (userDataForm) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const requestData = convertUserAssignFormToINewUser(userDataForm);
        return invite(requestData)
            .then((updatedUser) => {
            updateUserInDirectory(updatedUser);
        })
            .finally(() => setIsLoading(false));
    });
    const resendUserInvite = (userIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return resendInvite(userIdentity);
    });
    return {
        isLoading,
        userDirectory: userList,
        refetchUserDirectory: fetchOrgUsersList,
        assignUserAccess,
        addNewUser,
        resendInvite: resendUserInvite,
        offboardUser,
    };
};
