import { jsx as _jsx } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import { OrganizationRoleEnum } from '@frontend/domain/models/Roles';
import { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { AppAccessIcon } from './AppAccessIcon';
export const AssignUserApps = ({ index, userAssignForm, validateAppAccess, }) => {
    const { fields, update } = useFieldArray({
        control: userAssignForm.control,
        name: `organizationAssignments.${index}.appAccess`,
    });
    const userRole = useWatch({
        name: `organizationAssignments.${index}.role`,
        control: userAssignForm.control,
    });
    const canEdit = userRole !== OrganizationRoleEnum.LOCAL_ADMIN.toString() &&
        userRole !== OrganizationRoleEnum.CLIENT_CONCIERGE.toString();
    useEffect(() => {
        if (!canEdit) {
            fields.forEach((field, index) => {
                if (!field.isActive) {
                    update(index, Object.assign(Object.assign({}, field), { isActive: true }));
                    userAssignForm.clearErrors(`organizationAssignments.${index}.appAccess`);
                }
            });
        }
    }, [canEdit]);
    const onAccessToggle = (field, index) => {
        if (canEdit) {
            update(index, Object.assign(Object.assign({}, field), { isActive: !field.isActive }));
            const formData = userAssignForm.getValues();
            validateAppAccess(formData);
        }
    };
    return (_jsx(HStack, { children: fields.map((appAccess, index) => {
            return (_jsx(AppAccessIcon, { mr: 1, boxSize: 6, cursor: canEdit ? 'pointer' : 'default', app: appAccess.name, isActive: appAccess.isActive, onClick: () => onAccessToggle(appAccess, index) }, index));
        }) }));
};
