import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useManageOrganizationPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { PageHeader } from '../../sharedComponents/PageHeader';
import { ExpandableSidebar } from '@frontend/shared/src/components/ExpandableSidebar/ExpandableSidebar';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useReportList } from '@frontend/domain/hooks/Report/ReportListHook';
import { useEffect } from 'react';
import { environment } from '@/config/environment';
import { IAppNames } from '@frontend/domain/models/App';
export const PageLayout = ({ title, children, hideOrgsDropdown, hideAddOrgButton, hideAddUserButton = true, onOpenAddUser, }) => {
    var _a;
    const { organizations, onSelectOrganization, selectedOrganizationIdentity, isLoading, } = useOrganizationSummaries();
    // TODO: might not be needed after state management changes
    const { canAdd } = useManageOrganizationPermissions();
    const dropdownLabel = (_a = useBreakpointValue({
        base: 'Org',
        lg: 'Organization',
    })) !== null && _a !== void 0 ? _a : 'Organization';
    const showAddOrgButton = !hideAddOrgButton && canAdd ? true : false;
    const { user } = useCurrentUser();
    const { fetch, reports } = useReportList();
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetch(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const externalBaseRoutes = {
        base: environment.msiqUrl,
        organize: environment.fcaUrl,
        prioritize: environment.plannerUrl,
        opportunities: environment.piqUrl,
    };
    return (_jsx(ExpandableSidebar, { user: user, reports: reports, orgIdentity: selectedOrganizationIdentity, externalBasePaths: externalBaseRoutes, app: IAppNames.REPORTS, children: _jsxs(Box, { children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', hideOrgsDropdown: hideOrgsDropdown, organizations: organizations, isLoading: isLoading, selectedOrganizationIdentity: selectedOrganizationIdentity, onSelectOrganization: onSelectOrganization, dropdownLabel: dropdownLabel, showAddOrgButton: showAddOrgButton, showAddUserButton: !hideAddUserButton, onOpenAddUser: onOpenAddUser }), _jsx(Flex, { flexGrow: 1, flexDir: 'column', overflowY: 'auto', p: 10, children: children })] }) }));
};
