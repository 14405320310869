export const convertToIReport = (reportResponse) => {
    return {
        reportId: reportResponse.reportId,
        organizationId: reportResponse.organizationId,
        organizationKey: reportResponse.organizationKey,
        workspaceKey: reportResponse.workspaceKey,
        reportKey: reportResponse.reportKey,
        reportName: reportResponse.reportName,
        isPublished: reportResponse.isPublished,
        appContext: reportResponse.appContext,
        isCustom: reportResponse.isCustom,
        lastUpdated: reportResponse.lastUpdated,
    };
};
export const convertToIReportList = (reports) => {
    const onAppContext = (appContext) => (report) => report.appContext === appContext;
    const organizeReports = reports.filter(onAppContext('Organize'));
    const prioritizeReports = reports.filter(onAppContext('Prioritize'));
    const optimizeReports = reports.filter(onAppContext('Optimize'));
    return {
        organize: organizeReports.map(convertToIReportSummary),
        prioritize: prioritizeReports.map(convertToIReportSummary),
        optimize: optimizeReports.map(convertToIReportSummary),
    };
};
export const convertToIReportSummary = (report) => {
    return {
        id: report.reportId,
        name: report.reportName,
        showCustomer: report.isPublished,
    };
};
