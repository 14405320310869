import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from '@chakra-ui/react';
import { Form, } from '@frontend/design-system/components/FormModal/FormModal';
import { NumberToggleInput } from '@frontend/design-system/components/Inputs/NumberToggleInput';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useEffect, useState } from 'react';
import { validateName } from '../../../utils/formValidations';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsInput } from './SettingsInput';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const PrioritizationWeightInput = ({ canEdit, pageMode, originalData, onRemove, prioritizationAlgorithms, algorithmCriteriaList, acceptPrioritizations, setPageMode, currentTabIndex, criteriaOptions, setAlgorithmIndexToAdd, priorityCriteria, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [prioritizationInput, setPrioritizationInput] = useState(originalData);
    const [uniqValidationError, setUniqValidationError] = useState(undefined);
    const [reqValidationError, setReqValidationError] = useState(undefined);
    const [isSelected, setIsSelected] = useState(undefined);
    const PRIORITIZATION_UNIQ_NAME_ERROR_MSG = 'Prioritization weight name must be unique';
    const PRIORITIZATION_REQ_NAME_ERROR_MSG = 'Prioritization weight name is required';
    useEffect(() => {
        if (pageMode === SettingsPageMode.ADD_NEW) {
            setIsSelected(undefined);
            setUniqValidationError(undefined);
            setReqValidationError(PRIORITIZATION_REQ_NAME_ERROR_MSG);
        }
    }, [pageMode]);
    const validateNameInput = (value, onInvalidName, onValidName) => {
        const optionsNames = criteriaOptions.map((option) => {
            return { name: option.label };
        });
        validateName(value, optionsNames, onInvalidName, onValidName);
    };
    const onChangeNumberInput = (value) => {
        setPrioritizationInput(Object.assign(Object.assign({}, prioritizationInput), { name: (prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.name) || '', weight: Number((+value / 100).toFixed(2)) }));
    };
    const onChangeNameInput = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '') {
            setReqValidationError(PRIORITIZATION_REQ_NAME_ERROR_MSG);
        }
        else {
            setReqValidationError(undefined);
            validateNameInput(inputValue, () => {
                setUniqValidationError(PRIORITIZATION_UNIQ_NAME_ERROR_MSG);
            }, () => {
                setUniqValidationError(undefined);
            });
        }
        setPrioritizationInput(Object.assign(Object.assign({}, prioritizationInput), { name: e.target.value, weight: prioritizationInput ? prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.weight : 0 }));
    };
    const normalizeName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
    };
    const onChangeNameDropdownInput = (e) => {
        if (e) {
            const inputValue = e.value.toString();
            if (inputValue === '') {
                setReqValidationError(PRIORITIZATION_REQ_NAME_ERROR_MSG);
                return;
            }
            // checking value in the network state to handle case of selecting from existing options
            const existingSavedCriteria = priorityCriteria.find((criteria) => normalizeName(criteria.name) === normalizeName(inputValue));
            // checking value in the local state to account for option renaming
            const existingCriteriaOption = prioritizationAlgorithms
                .flatMap((algorithm) => algorithm.criteria)
                .find((criteria) => normalizeName(criteria.name) ===
                normalizeName(inputValue));
            let newPrioritizationInput;
            if (existingSavedCriteria) {
                newPrioritizationInput = {
                    identity: existingSavedCriteria.identity,
                    name: inputValue,
                    weight: (prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.weight) || 0,
                };
            }
            else if (existingCriteriaOption) {
                newPrioritizationInput = {
                    identity: existingCriteriaOption.identity,
                    name: inputValue,
                    weight: (prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.weight) || 0,
                };
            }
            else {
                newPrioritizationInput = {
                    name: inputValue,
                    weight: (prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.weight) || 0,
                };
            }
            setPrioritizationInput(newPrioritizationInput);
            setReqValidationError(undefined);
        }
    };
    const onAccept = () => {
        if (prioritizationInput) {
            if (prioritizationInput.name === '') {
                onCancel();
            }
            else {
                acceptPrioritizations(prioritizationInput, currentTabIndex, originalData);
            }
        }
        setIsSelected(undefined);
        setAlgorithmIndexToAdd(undefined);
        setPageMode(SettingsPageMode.VIEW);
    };
    const onCancel = () => {
        setIsSelected(undefined);
        setUniqValidationError(undefined);
        setReqValidationError(undefined);
        setAlgorithmIndexToAdd(undefined);
        setPageMode(SettingsPageMode.VIEW);
    };
    const onEdit = (data) => {
        setUniqValidationError(undefined);
        setReqValidationError(undefined);
        setIsSelected(data);
        setPrioritizationInput(data);
        setPageMode(SettingsPageMode.EDIT);
    };
    return (_jsxs(Flex, { flexDirection: 'column', children: [_jsx(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: { justifyContent: 'space-between' }, children: _jsxs(_Fragment, { children: [(pageMode === SettingsPageMode.EDIT && isSelected) ||
                            (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsx(_Fragment, { children: pageMode === SettingsPageMode.EDIT &&
                                isSelected ? (_jsx(SettingsInput, { validationError: uniqValidationError, placeholder: 'Name', data: prioritizationInput
                                    ? prioritizationInput.name
                                    : '', onChange: onChangeNameInput, characterLimit: 30 })) : (_jsx(Form.CreatableDropdown, { label: '', selectFrom: criteriaOptions.filter((option) => !algorithmCriteriaList.some((optionInUse) => option.value ===
                                    optionInUse.name)), placeholder: 'Name', value: prioritizationInput && {
                                    label: prioritizationInput.name,
                                    value: prioritizationInput.name,
                                }, 
                                // handle change events on the select
                                onChange: (e) => {
                                    onChangeNameDropdownInput(e);
                                }, 
                                // handle change events on the input
                                onInputChange: (e) => {
                                    validateNameInput(e, () => {
                                        setUniqValidationError(PRIORITIZATION_UNIQ_NAME_ERROR_MSG);
                                    }, () => {
                                        setUniqValidationError(undefined);
                                    });
                                }, 
                                // TODO adjust parsing of error to use react hook form validation instead
                                isValidNewOption: () => uniqValidationError ? false : true, filterOption: (option, inputValue) => {
                                    const normalize = (str) => str
                                        .toLowerCase()
                                        .replace(/\s+/g, '');
                                    return normalize(option.label).includes(normalize(inputValue));
                                }, maxLength: 30, chakraStyles: {
                                    menuList: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { borderRadius: '0px 0px 5px 5px' })),
                                    dropdownIndicator: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { background: 'none', borderLeft: 'none' })),
                                    indicatorSeparator: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { borderLeft: 'none' })),
                                    option: (baseStyles, state) => (Object.assign(Object.assign({}, baseStyles), { background: state.isFocused
                                            ? '#FEF3EA'
                                            : 'none' })),
                                } })) })) : (_jsx(Text, { children: originalData === null || originalData === void 0 ? void 0 : originalData.name })), hoverMode &&
                            originalData &&
                            pageMode === SettingsPageMode.VIEW &&
                            canEdit && (_jsx(SettingsEditDeleteBtnGroup, { btnLabel: 'Prioritization Weight', data: originalData, onEdit: onEdit, onDelete: () => onRemove(originalData, currentTabIndex), disableDelete: algorithmCriteriaList.length === 1 })), (pageMode !== SettingsPageMode.VIEW && isSelected) ||
                            (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsxs(_Fragment, { children: [_jsx(NumberToggleInput, { data: (originalData === null || originalData === void 0 ? void 0 : originalData.weight)
                                        ? Number(((originalData === null || originalData === void 0 ? void 0 : originalData.weight) * 100).toFixed(0))
                                        : 0, onChange: onChangeNumberInput, minValue: 0, maxValue: 100 }), _jsx(SettingsSaveCancelBtnGroup, { btnLabel: 'Prioritization Weight', validationError: uniqValidationError || reqValidationError, data: originalData || {
                                        name: '',
                                        weight: prioritizationInput === null || prioritizationInput === void 0 ? void 0 : prioritizationInput.weight,
                                    }, onSave: onAccept, onCancel: onCancel })] })) : (_jsxs(Text, { children: [(originalData === null || originalData === void 0 ? void 0 : originalData.weight)
                                    ? Number(((originalData === null || originalData === void 0 ? void 0 : originalData.weight) * 100).toFixed(0))
                                    : 0, "%"] }))] }) }), uniqValidationError && (_jsx(Text, { mt: '5px', color: 'red', fontSize: '12px', children: uniqValidationError }))] }));
};
