var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon, Tooltip } from '@chakra-ui/react';
import { MyOpportunitiesDisabledIcon, MyOpportunitiesDisabledSmallIcon, MyOpportunitiesEnabledIcon, MyOpportunitiesEnabledSmallIcon, } from '../../icons';
export const MyOpportunitiesLogo = (_a) => {
    var { fullLogo } = _a, props = __rest(_a, ["fullLogo"]);
    return (_jsx(_Fragment, { children: fullLogo ? (_jsx(Icon, Object.assign({ width: '41px', height: '41px', viewBox: '0 0 41 41', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, props, { children: _jsx(MyOpportunitiesEnabledIcon, {}) }))) : (_jsx(Tooltip, { label: 'myOpportunities', children: _jsx(Icon, Object.assign({ width: '25px', height: '24px', viewBox: '0 0 25 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, props, { children: _jsx(MyOpportunitiesEnabledSmallIcon, {}) })) })) }));
};
export const MyOpportunitiesLogoDisabled = (_a) => {
    var { fullLogo } = _a, props = __rest(_a, ["fullLogo"]);
    return (_jsx(_Fragment, { children: fullLogo ? (_jsx(Icon, Object.assign({ width: '41px', height: '41px', viewBox: '0 0 41 41', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, props, { children: _jsx(MyOpportunitiesDisabledIcon, {}) }))) : (_jsx(Tooltip, { label: 'myOpportunities', children: _jsx(Icon, Object.assign({ width: '25px', height: '24px', viewBox: '0 0 25 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, props, { children: _jsx(MyOpportunitiesDisabledSmallIcon, {}) })) })) }));
};
