import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';
export const PhoneInput = ({ label, data, onInputChange, required, constraints, }) => {
    const formatPhoneNumber = (value) => {
        const numericValue = value.replace(/\D/g, '');
        const phoneNumber = numericValue.slice(0, 10);
        const extension = numericValue.slice(10);
        let formattedPhoneNumber = phoneNumber;
        if (phoneNumber.length > 6) {
            formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }
        else if (phoneNumber.length > 3) {
            formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        else {
            formattedPhoneNumber = phoneNumber;
        }
        return extension
            ? `${formattedPhoneNumber} x${extension}`
            : formattedPhoneNumber;
    };
    return (_jsxs(FormControl, { isRequired: required, children: [_jsx(FormLabel, { pl: '0px', children: label }), _jsx("div", { style: { position: 'relative' }, children: _jsx(Input, { position: 'unset', pointerEvents: 'auto', value: data, fontSize: '14px', placeholder: label, onChange: (e) => onInputChange(formatPhoneNumber(e.target.value)), maxLength: constraints === null || constraints === void 0 ? void 0 : constraints.maxLength }) })] }));
};
