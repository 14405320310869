import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, VStack } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import React from 'react';
import { VscAdd } from 'react-icons/vsc';
import { FacilityExceptionInput } from './FacilityExceptionInput';
export const FacilityException = ({ form, facilities, orgIndex, role, activeIndex, setActiveIndex, isAddDisabled, setIsAddDisabled, fields, remove, append, }) => {
    return (_jsx(Box, { w: '100%', display: 'flex', children: _jsxs(VStack, { w: '100%', spacing: 4, align: 'flex-start', "data-testid": 'facility_exceptions', children: [fields.map((field, index) => {
                    const selectedFacilities = fields
                        .filter((_, i) => i !== index)
                        .map((f) => f.identity);
                    if (field.role.toString() === role.toString()) {
                        return (_jsx(FacilityExceptionInput, { index: index, form: form, removeException: () => {
                                remove(index);
                            }, allFacilities: facilities, availableFacilities: facilities === null || facilities === void 0 ? void 0 : facilities.filter((facility) => !selectedFacilities.includes(facility.identity)), editMode: activeIndex === index, onEdit: () => setActiveIndex(index), orgIndex: orgIndex, onSelectChange: (value) => {
                                if (value) {
                                    setActiveIndex(null);
                                    setIsAddDisabled(false);
                                }
                            }, facility: field }, field.id));
                    }
                }), _jsx(Button, { text: 'Add new', onClick: () => {
                        const emptyExceptionForm = {
                            identity: undefined,
                            role: role,
                        };
                        append(emptyExceptionForm);
                        setActiveIndex(fields.length);
                        setIsAddDisabled(true);
                    }, isDisabled: isAddDisabled, icon: _jsx(VscAdd, {}), iconPosition: 'left', buttonType: 'customLink' })] }) }));
};
