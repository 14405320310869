import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivateWarning } from './ActivateWarning';
import { OnboardingUserForm } from './OnboardingUserForm';
export const OnboardingUser = ({ children, }) => {
    const navigate = useNavigate();
    const { user, updateUserProfile, status } = useCurrentUser();
    const { isLoading, isActive, isEulaAccepted } = status;
    useEffect(() => {
        if (!isActive) {
            const timer = setTimeout(() => {
                navigate(AppPaths.logout);
            }, 2 * 60 * 1000);
            return () => clearTimeout(timer);
        }
    }, [isActive]);
    if (isLoading || !user) {
        return _jsx(_Fragment, {});
    }
    if (!isActive) {
        return _jsx(ActivateWarning, {});
    }
    return (_jsx(_Fragment, { children: isEulaAccepted ? (children) : (_jsx(OnboardingUserForm, { isLoading: isLoading, user: user, onSave: updateUserProfile })) }));
};
