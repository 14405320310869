import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from '@chakra-ui/react';
import { BasicSelect } from '@frontend/design-system/components/Inputs/BasicSelect';
import BodyRegular from '@frontend/design-system/components/Typography/Body/Body';
import { isSystemAdmin } from '@frontend/domain/contexts/Authorization/Roles';
import { toFriendlySystemRole } from '@frontend/domain/conversion/roles';
import { OrganizationRoleEnum, SystemRole, } from '@frontend/domain/models/Roles';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
export const UserSystemRoleSelector = ({ form, currentUserRole, fieldArray, primaryContactOrgsAppAccess, editModal, isDisabled, }) => {
    const dropdownOptions = currentUserRole === SystemRole.SUPER_ADMINISTRATOR
        ? [
            {
                label: toFriendlySystemRole(SystemRole.SUPER_ADMINISTRATOR),
                value: SystemRole.SUPER_ADMINISTRATOR,
            },
            {
                label: toFriendlySystemRole(SystemRole.ORGANIZATION_APPROVER),
                value: SystemRole.ORGANIZATION_APPROVER,
            },
            {
                label: toFriendlySystemRole(SystemRole.USER),
                value: SystemRole.USER,
            },
        ]
        : [
            {
                label: toFriendlySystemRole(SystemRole.ORGANIZATION_APPROVER),
                value: SystemRole.ORGANIZATION_APPROVER,
            },
            {
                label: toFriendlySystemRole(SystemRole.USER),
                value: SystemRole.USER,
            },
        ];
    const getText = (editUserRole) => {
        switch (editUserRole) {
            case SystemRole.SUPER_ADMINISTRATOR:
                return 'Access to all organizations in mySiteIQ. Can add, edit, and deactivate all users.';
            case SystemRole.ORGANIZATION_APPROVER:
                return 'Access to all organizations in mySiteIQ. Can add, edit, and deactivate all users except for super admin.';
            default:
                return null;
        }
    };
    const initialSystemRoleRef = useRef(form.getValues('systemRole')); //initial system role of the user
    const handleSystemRoleChange = (currentRole, newRole) => {
        if (isSystemAdmin(initialSystemRoleRef.current) &&
            isSystemAdmin(currentRole) &&
            newRole === SystemRole.USER) {
            if (primaryContactOrgsAppAccess.length > 0) {
                primaryContactOrgsAppAccess.forEach((organization) => {
                    const isAdded = form
                        .getValues('organizationAssignments')
                        .some((orgAssignment) => orgAssignment.identity === organization.identity);
                    if (!isAdded) {
                        fieldArray.append({
                            identity: organization.identity,
                            name: organization.name,
                            role: OrganizationRoleEnum.CLIENT_CONCIERGE,
                            facilityExceptions: [],
                            appAccess: organization.appAccess.filter((app) => app.isActive),
                        });
                    }
                });
            }
        }
    };
    return (_jsx(Controller, { control: form.control, name: 'systemRole', rules: {
            required: true,
        }, render: ({ field }) => (_jsxs(Flex, { w: '100%', gap: 4, alignItems: 'end', justifyItems: 'space-between', children: [_jsx(Box, { flex: '0 0 50%', children: _jsx(BasicSelect, { value: field.value, options: dropdownOptions, onChange: (e) => {
                            field.onChange(e);
                            editModal &&
                                handleSystemRoleChange(field.value, e);
                        }, required: true, label: 'mySiteIQ role', isDisabled: isDisabled }) }), isSystemAdmin(field.value) && (_jsx(Box, { flex: '0 0 50%', children: _jsx(BodyRegular, { color: 'secondary.dark.300', children: getText(field.value) }) }))] })) }));
};
