export var SystemRole;
(function (SystemRole) {
    SystemRole["SUPER_ADMINISTRATOR"] = "SuperAdministrator";
    SystemRole["ORGANIZATION_APPROVER"] = "OrganizationApprover";
    SystemRole["USER"] = "User";
    SystemRole["EMPTY"] = "";
})(SystemRole || (SystemRole = {}));
export var OrganizationRoleEnum;
(function (OrganizationRoleEnum) {
    OrganizationRoleEnum["CLIENT_CONCIERGE"] = "ClientConcierge";
    OrganizationRoleEnum["LOCAL_ADMIN"] = "LocalAdministrator";
    OrganizationRoleEnum["MEMBER"] = "Member";
    OrganizationRoleEnum["VISITOR"] = "Visitor";
    OrganizationRoleEnum["NONE"] = "None";
    OrganizationRoleEnum["EMPTY"] = "";
})(OrganizationRoleEnum || (OrganizationRoleEnum = {}));
export var FacilityRoleEnum;
(function (FacilityRoleEnum) {
    FacilityRoleEnum["MEMBER"] = "Member";
    FacilityRoleEnum["VISITOR"] = "Visitor";
    FacilityRoleEnum["NONE"] = "None";
})(FacilityRoleEnum || (FacilityRoleEnum = {}));
