import { SystemRoleEnum } from '../../msiq/src/objects/UserData';
import { OrganizationRoleEnum } from '../models/Roles';
import { createGetHandler, createPostHandler, } from '../testing/msw/handlerUtils';
import { OrganizationAssignmentResponseBuilder, UserProfileResponseBuilder, } from '../testing/platformUserMocks';
const userEndpoints = {
    load: '/api/v1/User/load',
    configure: '/api/v1/User/configure',
    list: '/api/v1/User/list',
    offboard: '/api/v1/User/offboard',
};
const userLoadHandler = createGetHandler(userEndpoints.load, new UserProfileResponseBuilder({}).build());
const userConfigureHandler = createGetHandler(userEndpoints.configure, new UserProfileResponseBuilder({}).build());
const userListHandler = createGetHandler(userEndpoints.list, [
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({
        firstName: '1 Super Admin',
        systemRole: SystemRoleEnum.SUPER_ADMIN,
        organizationAssignments: [],
    }).build(),
    new UserProfileResponseBuilder({
        firstName: '2 Org Approver',
        systemRole: SystemRoleEnum.ORG_APPROVER,
        organizationAssignments: [],
    }).build(),
    new UserProfileResponseBuilder({
        firstName: '3 CC',
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({
                role: OrganizationRoleEnum.CLIENT_CONCIERGE,
            }).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        firstName: '4 LA',
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({
                role: OrganizationRoleEnum.LOCAL_ADMIN,
            }).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        firstName: '5 LA',
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({
                role: OrganizationRoleEnum.LOCAL_ADMIN,
            }).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        firstName: '6 Visitor',
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({
                role: OrganizationRoleEnum.VISITOR,
            }).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({}).build(),
            new OrganizationAssignmentResponseBuilder({}).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({
        systemRole: SystemRoleEnum.USER,
        organizationAssignments: [
            new OrganizationAssignmentResponseBuilder({}).build(),
        ],
    }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
    new UserProfileResponseBuilder({ systemRole: SystemRoleEnum.USER }).build(),
]);
const userOffboardHandler = createPostHandler(userEndpoints.offboard, {
    message: 'OK',
});
// remove userLoadHandler if not testing the user that is logged in.
const userHandlers = [
    userLoadHandler,
    userListHandler,
    userConfigureHandler,
    userOffboardHandler,
];
export const userHandler = {
    endpoints: userEndpoints,
    handlers: userHandlers,
};
