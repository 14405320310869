import { jsx as _jsx } from "react/jsx-runtime";
import { useManageOrganizationPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { models } from 'powerbi-client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
import { useAppDispatch } from '../../store/hooks';
import { setAvailable } from '../../store/sidebarSlice';
import { ReportContent } from './ReportContent';
import './ReportPage.css';
export const ReportPage = () => {
    var _a;
    const dispatch = useAppDispatch();
    const params = useParams();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const [token, setToken] = useState();
    const [retrievalError, setRetrievalError] = useState(false);
    const [reportTitle, setReportTitle] = useState('Reports');
    const { fetchReportToken } = usePlatformOrgApi();
    const { canRefreshReports, canEdit: canEditReports } = useManageOrganizationPermissions();
    const [reportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: undefined,
        accessToken: undefined,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
            },
        },
    });
    useEffect(() => {
        dispatch(setAvailable(true));
    }, []);
    useEffect(() => {
        if (params.rid) {
            fetchReportToken(parseInt(params.rid))
                .then((token) => {
                setToken(token);
                setRetrievalError(false);
                setReportConfig(Object.assign(Object.assign({}, reportConfig), { embedUrl: token.embedUrl, accessToken: token.embedToken }));
            })
                .catch((error) => {
                console.error(error);
                setRetrievalError(true);
            });
        }
    }, [params.rid]);
    return (_jsx(PageLayout, { title: reportTitle, hideAddOrgButton: true, hideOrgsDropdown: true, children: _jsx(ReportContent, { org: organizationDetails, retrievalError: retrievalError, token: token, reportId: (_a = params.rid) !== null && _a !== void 0 ? _a : '', embedConfig: reportConfig, onReportLoaded: (report) => {
                setReportTitle(`Reports - ${report.appContext}`);
            }, canEditReports: canEditReports, canRefreshReports: canRefreshReports }) }));
};
