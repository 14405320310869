import { OrganizationRoleEnum, SystemRole } from '../../models/Roles';
import { useOrganizationSummaries } from '../Organization/OrganizationSummaryContext';
import { usePlannerSettingsContext } from '../Settings/PlannerSettingsContext';
import { useCurrentUser } from '../User/UserContext';
import { canEditFacility, isClientConciergePlus, isLocalAdminPlus, isOrgMemberPlus, isPrimaryContact, isSuperAdmin, isSystemAdmin, UserManagementContext, } from './Roles';
export const useNavigationPermissions = () => {
    const { user, status } = useCurrentUser();
    const isLoading = status.isLoading;
    return {
        isLoading,
        canSeeAdminPage: !isLoading ? isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole) : false,
    };
};
export const useFcaPermissions = (facilityIdentity) => {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const isLoading = status.isLoading && !selectedOrganizationIdentity;
    const hasRequiredInfo = user && selectedOrganizationIdentity;
    const isLAPlus = hasRequiredInfo
        ? isLocalAdminPlus(user, selectedOrganizationIdentity)
        : false;
    return {
        isLoading,
        canDownload: isLAPlus,
        canEdit: hasRequiredInfo && facilityIdentity
            ? canEditFacility(user, selectedOrganizationIdentity, facilityIdentity)
            : false,
        canManageFacility: isLAPlus,
    };
};
export const usePiqPermissions = () => {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const isLoading = status.isLoading && !selectedOrganizationIdentity;
    const hasRequiredInfo = user && selectedOrganizationIdentity;
    return {
        isLoading,
        canEditFacility: hasRequiredInfo
            ? isLocalAdminPlus(user, selectedOrganizationIdentity)
            : false,
        canEditFacilityPriority: hasRequiredInfo
            ? isOrgMemberPlus(user, selectedOrganizationIdentity)
            : false,
    };
};
export const usePlannerPermissions = (projects) => {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { orgFundingTypes, orgProjectTypes, selectPrioritizationCriteriaForProject, } = usePlannerSettingsContext();
    const prioritizationCriteria = selectPrioritizationCriteriaForProject();
    const isLoading = status.isLoading && !selectedOrganizationIdentity;
    const hasRequiredInfo = user &&
        selectedOrganizationIdentity &&
        orgFundingTypes &&
        orgProjectTypes;
    const isMemberPlus = hasRequiredInfo
        ? isOrgMemberPlus(user, selectedOrganizationIdentity)
        : false;
    const isCCPlus = hasRequiredInfo
        ? isClientConciergePlus(user, selectedOrganizationIdentity)
        : false;
    const isLAPlus = hasRequiredInfo
        ? isLocalAdminPlus(user, selectedOrganizationIdentity)
        : false;
    return {
        isLoading,
        canEdit: isMemberPlus,
        canAddProject: orgFundingTypes.length > 0 &&
            orgProjectTypes.length > 0 &&
            prioritizationCriteria &&
            isMemberPlus,
        canAddScenario: orgFundingTypes && projects.length > 0 && isMemberPlus,
        canExportProjects: isCCPlus,
        canManageScenario: isLAPlus,
    };
};
export const useManageUserPermissions = () => {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const isLoading = status.isLoading && !selectedOrganizationIdentity;
    const hasRequiredInfo = user && selectedOrganizationIdentity;
    const canDelete = (context, userToDelete) => {
        var _a, _b;
        if (!hasRequiredInfo ||
            isPrimaryContact(context, userToDelete, selectedOrganizationIdentity)) {
            return false;
        }
        const canDeleteCC = ((_a = userToDelete.organizationRoles.find((role) => role.organizationIdentity === selectedOrganizationIdentity)) === null || _a === void 0 ? void 0 : _a.role) === OrganizationRoleEnum.CLIENT_CONCIERGE &&
            ((_b = user === null || user === void 0 ? void 0 : user.organizationRoles.find((orgRole) => orgRole.organizationIdentity ===
                selectedOrganizationIdentity)) === null || _b === void 0 ? void 0 : _b.role) !== OrganizationRoleEnum.CLIENT_CONCIERGE
            ? false
            : true;
        switch (userToDelete.systemRole) {
            case SystemRole.SUPER_ADMINISTRATOR:
                return false;
            case SystemRole.ORGANIZATION_APPROVER:
                return isSuperAdmin(user === null || user === void 0 ? void 0 : user.systemRole);
            case SystemRole.USER:
                return (isLocalAdminPlus(user, selectedOrganizationIdentity) &&
                    canDeleteCC);
            default:
                return false;
        }
    };
    const canEdit = (context, userToEdit, organizationIdentity) => {
        var _a, _b;
        if (!hasRequiredInfo) {
            return false;
        }
        const canEditCC = ((_a = userToEdit.organizationRoles.find((role) => role.organizationIdentity === selectedOrganizationIdentity)) === null || _a === void 0 ? void 0 : _a.role) === OrganizationRoleEnum.CLIENT_CONCIERGE &&
            ((_b = user === null || user === void 0 ? void 0 : user.organizationRoles.find((orgRole) => orgRole.organizationIdentity ===
                selectedOrganizationIdentity)) === null || _b === void 0 ? void 0 : _b.role) !== OrganizationRoleEnum.CLIENT_CONCIERGE
            ? false
            : true;
        switch (context) {
            case UserManagementContext.ADMIN:
                return isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole);
            case UserManagementContext.ORG:
                return (isLocalAdminPlus(user, organizationIdentity) && canEditCC);
            default:
                return false;
        }
    };
    // determines if system or org admin
    const isContextAdmin = (context, organizationIdentity) => {
        if (!hasRequiredInfo) {
            return false;
        }
        switch (context) {
            case UserManagementContext.ADMIN:
                return isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole);
            case UserManagementContext.ORG:
                return isLocalAdminPlus(user, organizationIdentity);
            default:
                return false;
        }
    };
    return {
        isLoading,
        canEdit: canEdit,
        canAdd: isContextAdmin,
        canDelete: canDelete,
        canResendInvite: isContextAdmin,
        showFacilityUsers: !isLocalAdminPlus(user, selectedOrganizationIdentity),
        // will be replaced by canAdd function in updated user mgmt
        deprecatedCanAddLocalAdmin: isClientConciergePlus(user, selectedOrganizationIdentity),
    };
};
export const useManageOrganizationPermissions = () => {
    const { user, status } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const isLoading = status.isLoading && !selectedOrganizationIdentity;
    const hasRequiredInfo = user && selectedOrganizationIdentity;
    const isLAPlus = hasRequiredInfo
        ? isLocalAdminPlus(user, selectedOrganizationIdentity)
        : false;
    const isCCPlus = isClientConciergePlus(user, selectedOrganizationIdentity);
    const isAdmin = isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole);
    return {
        isLoading,
        canEdit: isLAPlus,
        canAdd: isAdmin,
        canDelete: isAdmin,
        canEditAppAccess: isAdmin,
        canSeeAdminOrgSettings: isCCPlus,
        canManagePhotoTags: isLAPlus,
        canViewReports: isLocalAdminPlus(user, selectedOrganizationIdentity),
        canRefreshReports: isOrgMemberPlus(user, selectedOrganizationIdentity),
        canEditBusinessUnit: isAdmin,
    };
};
